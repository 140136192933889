@charset "utf-8";
/*------------------------------------------------------------------
Project: DT Skill
-------------------------------------------------------------------*/
/* @import "./font-awesome.min.css"; */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* ==============================
   Common styles
   ============================== */
  
.align-center{ text-align:center !important; }
.align-left{ text-align:left !important;}
.align-right{ text-align:right !important; }
.text-justify{ text-align: justify !important; }
.left{float:left !important;}
.right{float:right !important;}
.relative{position:relative;}
.hidden{display:none;}
.inline-block{display:inline-block;}
.white{ color: #fff !important; }
.black{ color: #111 !important; }
.color{ color: #182E46 !important; }
.color-2 { color: #B9943C !important; }
.text-gray {color: #8F8F8F !important;}
.glass{ opacity: .5; }
.overflow {overflow: visible !important;}
.overflowz99 {overflow: visible !important; z-index: 99;}
.underline {text-decoration: underline;}
.zindex {z-index: 99; position: relative;}
.italic {font-style: italic;}
.fs-12 {font-size: 12px;}
.fs-13 {font-size: 13px;}
.fs-14 {font-size: 14px;}
.fs-15 {font-size: 15px;}
.fs-16 {font-size: 16px;}
.fs-17 {font-size: 17px;}
.fs-18 {font-size: 18px;}
.fs-19 {font-size: 19px;}
.fs-20 {font-size: 20px;}
.fs-22 {font-size: 22px;}
.fs-24 {font-size: 24px;}
.fs-26 {font-size: 26px;}

.fw-300 {font-weight: 300 !important}
.fw-400 {font-weight: 400 !important}
.fw-500 {font-weight: 500 !important}
.fw-600 {font-weight: 600 !important}
.fw-700 {font-weight: 700 !important}

.lh-1 {line-height:1 !important}
.lh-2 {line-height:1.2 !important}
.lh-3 {line-height:1.4 !important}
.lh-4 {line-height:1.6 !important}
.bg-pos-bottom {
    background-position: bottom center !important;
    background-size: cover !important;
}
.bg-pos-center {
    background-position: center center !important;
    background-size: cover !important;
}
.bg-pos-topcenter {
    background-position: top center !important;
    background-size: cover !important;
}
body.modal-open {
    padding-right: 0 !important;
    overflow: visible !important;
}
body { padding-right: 0 !important }
body > iframe {
    pointer-events: none;
}
.image-fullwidth img{
    width: 100%;
}
.stick-fixed{
    position: fixed !important;
    top: 0;
    left: 0;
}
.round{
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
}
.round2{
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    border-radius: 30px !important;
}
::-moz-selection{ color: #fff; background:#000; }
::-webkit-selection{ color: #fff;    background:#000; }
::selection{ color: #fff;    background:#000; }

.animate, .animate *{
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.no-animate, .no-animate *{
    -webkit-transition: 0 none !important;  
    -moz-transition: 0 none !important;  
    -ms-transition: 0 none !important;
    -o-transition: 0 none !important;    
    transition:0 none !important;  
}

.shadow{
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    -moz-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.white-shadow{
    -webkit-box-shadow: 0px 0px 200px rgba(255, 255, 255, .2);
    -moz-box-shadow: 0px 0px 200px rgba(255, 255, 255, .2);
    box-shadow: 0px 0px 200px rgba(255, 255, 255, .2);
}

.clearlist, .clearlist li {
    list-style: none;
    padding: 0;
    margin: 0;
    background: none;
}

.full-wrapper {
    margin: 0 2%;
}
@media (min-width: 768px) {
    .container{
    	width: 750px;
    }
}
@media (min-width: 992px){ 
    .container{
        width: 970px;
    }
}
@media (min-width: 1200px){ 
    .container{
        width: 1170px;
    }
}
.container-fluid{
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 20px;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.container-1400{
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 2%;
    padding-right: 2%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
:root{
    scroll-behavior: auto;
}
html{
    overflow-y: scroll;
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth !important;
}
html, body{
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth !important;
}
.page{
    overflow: hidden;
}

.body-masked{
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff url(../images/loader.gif) no-repeat center center;
    z-index: 100000;    
}

.mask-delay{
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    animation-delay: 0.75s;
}
.mask-speed{
    -webkit-animation-duration: 0.25s !important;
    -moz-animation-duration: 0.25s !important;
    animation-duration: 0.25s !important;
} 
iframe{
    border: none;
}
a, b, div, ul, li{
    -webkit-tap-highlight-color: rgba(0,0,0,0); 
    -webkit-tap-highlight-color: transparent; 
    -moz-outline-: none;
}
a:focus:not(.focus-visible),
a:active,
section:focus,
div:active,
div:focus{
    -moz-outline: none;
    outline: none;
}
a:focus.focus-visible,
button:focus.focus-visible,
div:focus.focus-visible{
    -moz-outline: 2px dotted #36a367 !important;
    outline: 2px dotted #36a367 !important;
    outline-offset: 0 !important;
}

img:not([draggable]), embed, object, video{
    max-width: 100%;
    height: auto;
}



/* ==============================
   Page loader
   ============================== */

.CookieConsent {
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 30px;
    span {
        font-weight: 600;
        font-size: 16px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}

.page-loader{
    display:block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fefefe;
    z-index: 100000;    
}
.loader {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100001;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  .react-tel-input .form-control {
    width: 100% !important;
    height: 46px !important;
    outline: none;
    border-radius: 0 !important;
    border-right: none !important;
    background: #f5f5f5 !important;
    border-left: none !important;
    padding-left: 60px !important;
  }
  .react-tel-input .flag-dropdown {
    border: 1px solid #8F8F8F !important;
    border-right:  none !important;
  }
  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 55px !important;
    height: 100%;
    padding: 0 0 0 15px !important;
    border-radius: 0px !important;
}
.react-tel-input .selected-flag .arrow {
    left: 30px !important;
}
/* ==============================
   Typography
   ============================== */

body{
    color: #111;
    font-size: 14px;
    font-family: "Lato", sans-serif;
    line-height: 1.6;    
    -webkit-font-smoothing: antialiased;
}

a{
    color: #111;
    text-decoration: none;
}
a:hover{
    color: #888;
    text-decoration: none;
}

h1,h2,h3,h4,h5,h6{
    margin-bottom: 1.3em;
    line-height: 1.4;
    font-weight: 400;
   
}
h1, .h1{
    font-size: 32px;
}
h2, .h2,{
    font-size: 28px;
}
h3, .h3{
    font-size: 24px;
}
h4, .h4{
    font-size: 18px;
}
h5, .h5{
    font-size: 14px;
}
h6, .h6{
    font-size: 12px;
    font-weight: 700;
}
p{
    margin: 0 0 2em 0;
}
ul, ol{
    margin: 0 0 1.5em 0;
}
blockquote{
    margin: 3em 0 3em 0;
    padding: 0;
    border: none;
    background: none;
    font-style: normal;
    line-height: 1.7;
    color: #777;
}
blockquote p{
    margin-bottom: 1em;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.6;
}
blockquote footer{    
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
}
dl dt{
    margin-bottom: 5px;
}
dl dd{
    margin-bottom: 15px;
}
pre{
    background-color: #f9f9f9;
    border-color: #ddd;

}
hr{
    background-color: #000;
    opacity: .08;
}
hr.white{
    background-color: #fff
}
.uppercase{
    text-transform: uppercase;
    letter-spacing: 0px;
}
.font-alt{
    font-family: "Playfair Display", serif;
}
.normal{
    font-weight: normal;
}
.strong,
strong{
    font-weight: 700 !important;
}
.lead{
    margin: 1em 0 2em 0;
    font-size: 140%;
    line-height: 1.6;
}
.text{        
    font-size: 16px;
    font-weight: 300;
    color: #5f5f5f;
    line-height: 1.8;
}
/* ==============================
   Buttons
   ============================== */
  
.btn-mod,
a.btn-mod{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 4px 13px;
    color: #fff;
    background: rgba(34,34,34, .9);
    border: 2px solid transparent;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0px;
    
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    
    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.btn-mod:hover,
.btn-mod:focus,
a.btn-mod:hover,
a.btn-mod:focus{
    color: rgba(255,255,255, .85);
    background: rgba(0,0,0, .7);
    text-decoration: none;
    outline: none;
    border-color: transparent;    
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-mod:active{
    cursor: pointer !important;
    outline: none !important;
}

.btn-mod.btn-small{
    height: auto;
    padding: 4px 15px;
    font-size: 12px;
    letter-spacing: 0px;
} 
.btn-mod.btn-medium{
    height: auto;
    padding: 6px 20px;
    font-size: 13px;
} 
.btn-mod.btn-large{
    height: auto;
    padding: 10px 22px;
    font-size: 15px;
} 
.btn-mod.btn-large-lg{
    height: auto;
    padding: 6px 45px;
    font-size: 14px;
} 
.btn-mod.inline-flex {
    display: inline-flex;
    align-items: center;
}
.btn-mod.btn-large span {
    padding-right: 15px;
}
.btn-mod.btn-glass{
    color: rgba(255,255,255, .75);
    background: rgba(0,0,0, .40);
}
.btn-mod.btn-glass:hover,
.btn-mod.btn-glass:focus{
    color: rgba(255,255,255, 1);
    background: rgba(0,0,0, 1);
}

.btn-mod.btn-border{
    color: #151515;
    border: 2px solid #151515;
    background: transparent;
}
.btn-mod.btn-border:hover,
.btn-mod.btn-border:focus{
    color: #fff;
    border-color: transparent;
    background: #000;
}

.btn-mod.btn-border-c{
    color: #e41919;
    border: 2px solid #e41919;
    background: transparent;
}
.btn-mod.btn-border-c:hover,
.btn-mod.btn-border-c:focus{
    color: #fff;
    border-color: transparent;
    background: #e41919;
}

.btn-mod.btn-border-w{
    color: #fff;
    border: 2px solid rgba(255,255,255, .75);
    background: transparent;
}
.btn-mod.btn-border-w:hover,
.btn-mod.btn-border-w:focus{
    color: #111;
    border-color: transparent;
    background: #fff;
}

.btn-mod.btn-w{
    color: #182E46;
    background: #fff;
    border: 2px solid #B9943C
}
.btn-mod.btn-w:hover,
.btn-mod.btn-w:focus{
    color: #fff;
    background: #182E46;
    border: 2px solid #B9943C
}

.btn-mod.btn-w-color{
    color: #e41919;
    background: #fff;
}
.btn-mod.btn-w-color:hover,
.btn-mod.btn-w-color:focus{
    color: #151515;
    background: #fff;
}

.btn-mod.btn-color{
    color: #fff;
    background: #182E46;
    border: 2px solid #B9943C
}
.btn-mod.btn-color:hover,
.btn-mod.btn-color:focus{
    color: #fff;
    background: #B9943C;
    border: 2px solid #182E46
}

.btn-mod.btn-circle{
    -webkit-bordrgb(202, 210, 219)us: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}
.btn-mod.btn-round{
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
.btn-icon{
    position: relative;
    border: none;
    overflow: hidden;
}
.btn-icon.btn-small{
    overflow: hidden;
}
.btn-icon > span{
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    left: 0;
    color: #777;
    font-size: 48px;
    opacity: .2;
    
    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.btn-icon:hover > span{
    opacity: 0;
    
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
}
.btn-icon > span.white{
    color: #fff;
}
.btn-icon > span.black{
    color: #000;
}
.btn-full{
    width: 100%;
}

/* ==============================
   Home sections
   ============================== */
  
/*
 * Header common styles
 */

.home-section{
    width: 100%;
    display: block;    
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}

.home-content{
    display: table;
    width: 100%;
    height: 100%;
    text-align: left;
}


/* Home content */

.home-content{
    position: relative;
}
.home-text{
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}
.home-text-bottom {
    display: table-cell;
    height: 100%;
    vertical-align: bottom;
    padding-bottom: 120px;
}
.sliderDesc {
    font-size: 15px;
    color: #767676;
}
/* Scroll down icon */

@-webkit-keyframes scroll-down-anim {
    0%{ bottom: 2px; }
    50%{ bottom: 7px; }
    100%{ bottom: 2px; }
}
@-moz-keyframes scroll-down-anim {
    0%{ bottom: 2px; }
    50%{ bottom: 7px; }
    100%{ bottom: 2px; }
}
@-o-keyframes scroll-down-anim {
    0%{ bottom: 2px; }
    50%{ bottom: 7px; }
    100%{ bottom: 2px; }
}
@keyframes scroll-down-anim {
    0%{ bottom: 2px; }
    50%{ bottom: 7px; }
    100%{ bottom: 2px; }
}

.scroll-down{
    width: 40px;
    height: 40px;
    margin-left: -20px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    text-align: center;
    text-decoration: none;
    
    -webkit-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    -moz-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    -o-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 

}

.scroll-down:before{
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    margin: 0 0 0 -10px;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: #fff;
    
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
    -webkit-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    -moz-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    -o-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940); 
    transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    
    z-index: 1;
}

.scroll-down:hover:before{
    opacity: .6;
}


.scroll-down-icon{
    display: block;
    width: 10px;
    height: 10px;
    margin-left: -5px;
    position: absolute;
    left: 50%;
    bottom: 4px;
    color: #111;
    font-size: 15px;

    -webkit-animation: scroll-down-anim 1s infinite;
    -moz-animation: scroll-down-anim 1s infinite;
    -o-animation: scroll-down-anim 1s infinite;
    animation: scroll-down-anim 1s infinite; 
    
   
   z-index: 2;
}


/* 
 * Headings styles
 */


.hs-wrap{
    max-width: 570px;
    margin: 0 auto;
    padding: 47px 30px 53px;
    position: relative;
    text-align: center;    
    
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    
    border: 1px solid rgba(255,255,255, .5);
}
.js-height-parent {
    height: 760px;
}
.js-height-full {
    height: 100vh;
}
.js-height-parent-text {
    height: 450px;
}
.js-height-parent-book {
    height: 650px;
}
.hs-wrap-1{
    max-width: 350px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.hs-line-head {
    position: relative;
    text-transform: none;
    font-size: 60px;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -1px;
    color: #fff;
}
.hs-line-1{
    position: relative;
    text-transform: none;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: -1px;
    color: #fff;
}
.hs-line-1 span {
    font-weight: 700;
    color: #D80621;
}
.hs-line-2{
    margin-top: 0;
    position: relative;
    font-size: 17px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0em;
    line-height: 1.3;
    color: #fff;
}
.hs-line-3{
    position: relative;
    text-transform: none;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -1px;
    color: #fff;
}

.hs-line-4{
    margin-top: 0;
    position: relative;
    font-size: 13px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: 0px;
    color: #313131;
}
.hs-line-5{
    margin-top: 0;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    text-transform: none;
    letter-spacing: 0em;
    color: #2A394C;
}
.hs-line-6{
    margin-top: 0;
    position: relative;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.2;
    opacity: .35;
}
.hs-line-6.no-transp{
    opacity: 1;
}
.hs-line-7{
    margin-top: 0;
    position: relative;
    font-size: 17px;
    font-weight: 600;;
    color: #B9943C;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 1.2;
}
.hs-line-8{
    margin-top: 0;
    position: relative;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 1.2;
}
.hs-line-8.no-transp{
    opacity: 1;
}

.hs-line-9{
    margin-top: 0;
    position: relative;
    font-size: 96px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.2;
}
.hs-line-10{
    margin-top: 0;
    display: inline-block;
    position: relative;
    padding: 15px 24px;
    background: rgba(0,0,0, .75);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(255,255,255, .85);
}
.hs-line-10 > span{
    margin: 0 10px;
}
.hs-line-10.highlight{
    background: #e41919;
    opacity: .65;
}
.hs-line-10.transparent{
    background: none;
    color: #aaa;
    padding: 0;
}
.hs-line-11{
    margin-top: 0;
    position: relative;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    line-height: 1.8;
    opacity: .9;
}
.hs-line-12{
    margin-top: 0;
    position: relative;
    font-size: 80px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.4em;
}
.hs-line-13{
    margin-top: 0;
    position: relative;
    font-size: 96px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.3em;
}
.hs-line-14{
    margin-top: 0;
    position: relative;
    font-size: 48px;
    font-weight: 400;
    line-height: 1.4;
    text-transform: uppercase;
    letter-spacing: 0.7em;
}
.hs-line-15{
    margin-top: 0;
    position: relative;
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1.3;
}
.no-transp{
    opacity: 1 !important;
}

.fullwidth-galley-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 25;
}
.border-top {
    border-top: 1px solid #ddd;
}
.inner-container {
    padding-left: 30px;
}
.inner-container-right {
    padding-right: 30px;
}
.pics-abbr .capital {
    font-size: 40px;
    color: #182E46;
    font-weight: 500;
    margin-right: 15px;
    line-height: 50px;
    min-width: 50px;
    text-align: center;
}
.pics-abbr .desc {
    position: relative;
    width: 390px;
    border: 1px solid #182E46
}
.pics-abbr .desc-btn {
    color: #8F8F8F;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
}
.pics-abbr .desc-btn span {
    color: #B9943C;
    font-size: 20px;
    position: relative;
    font-weight: 700;
    letter-spacing: -0.5px;
    width: 75px;
    display: block;
}
.pics-abbr .desc-btn span:after {
    height: 2px;
    content: "-";
    font-size: 20px;
    position: absolute;
    right: -20px;
    top: 0px;
    background-repeat: no-repeat;
}
.desc-info img {
    max-width: 25px;
    margin-right: 5px;
}
/* Logo (in navigation panel)*/

.nav-logo-wrap{
    float: left;
    margin-right: 20px;
}
.nav-logo-wrap .logo{
    display: flex;
    align-items: center;
    height: 65px;
    margin-top: 10px;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.nav-logo-wrap .logo img{
    max-height: 100%;
}
.nav-logo-wrap .logo:before,
.nav-logo-wrap .logo:after{
    display: none;
}
.logo,
a.logo:hover{
    font-family: Dosis, arial, sans-serif;
    font-size: 18px;
    font-weight: 400 !important;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    text-decoration: none;
    color: rgba(0,0,0, .9);
}

/*
 * Desktop nav
 */

.main-nav{
    width: 100%;
    height: 75px !important;
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    background: transparent;
    
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow:  none;
    
    z-index: 1030;
    
    -webkit-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.menu-bg {
    background-color: #182E46 !important;
    height: 90px !important;
}
.menu-bg .inner-nav {
    margin-top: 25px;
}
.menu-bg .nav-logo-wrap .logo {
    height: 75px !important;
}
.small-height{
    height: 55px !important;
}
.menu-item {
    color: #fff;
    padding-right: 15px;
    font-size: 13px;
}
.small-height .inner-nav ul li a{
    height: 55px !important;
    line-height: 55px !important;
}
.inner-nav{
    display: inline-block;
    position: relative;
    float: right;
    margin-top: 20px;
}
.inner-nav ul{
    float: right;
    margin: auto;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.3;
    display: flex;
    align-items:center;
}
.inner-nav ul li {
    float: left;
    padding: 0 20px;
    position: relative;
}
.inner-nav ul li a{
    color: #fff;
    letter-spacing: 0px;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.inner-nav ul > li:first-child > a{

}  
.inner-nav ul li a:hover,
.inner-nav ul li a.active{
    color: #B9943C;
    text-decoration: none;
}
/*
 * Mobile nav
 */

.mobile-on .desktop-nav{
    display: none;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0 15px;
    
    z-index: 100;    
}
.mobile-on .full-wrapper .desktop-nav{
    padding: 0;
}


.mobile-on .desktop-nav ul{
    width: 100%;
    float: none;
    background: rgba(18,18,18, .97);
    position: relative;
    overflow-x: hidden;
}
.mobile-on .desktop-nav > ul{
    overflow-y: auto;
}
.mobile-on .desktop-nav ul li{
    display: block;
    float: none !important;
    overflow: hidden;
}
.mobile-on .desktop-nav ul li ul{
    padding: 0;
    border-bottom: 1px solid rgba(255,255,255, .1);
}
.mobile-on .desktop-nav ul li a{
    display: block;
    width: 100%;
    height: 38px !important;
    line-height: 38px !important;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: left;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(255,255,255, .065);
    color: #999;
    letter-spacing: 1px;
    cursor: pointer;
}
.mobile-on .desktop-nav ul li a:hover,
.mobile-on .desktop-nav ul li a.active{
    background: rgba(255,255,255, .03);
    color: #ddd;
}
.mobile-on .desktop-nav ul li a:focus{
    outline-offset: -2px !important; 
}


/* Menu sub */

.mobile-on .mn-has-sub > .fa{
    display: block;
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -8px;
    text-align: center;
    border: 1px solid rgba(255,255,255, .1);
    
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.mobile-on .js-opened > .mn-has-sub > .fa{
    border-color: rgba(255,255,255, .4);
    line-height: 14px;
}
.mobile-on .inner-nav li{
    margin: 0 !important;
}
.mobile-on .mn-sub{
    display: none;
    opacity: 0;
    position: relative;
    left: 0 !important;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: none !important;
    border-right: none !important;
    border-top: none ;
    z-index: 10;
}
.mobile-on .mn-sub li{
    padding-left: 10px;
}


.inner-nav li.js-opened .mn-sub{
    opacity: 1;
}


.mobile-nav{
    display: none;
    float: right;
    vertical-align: middle;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    border-left: 1px solid rgba(0,0,0, .05);
    border-right: 1px solid rgba(0,0,0, .05);
    cursor: pointer;
    
    -webkit-user-select: none;  
    -moz-user-select: none;     
    -ms-user-select: none;     
    -o-user-select: none;
    user-select: none;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    
    -webkit-transition-property: background, color, height, line-height;
    -moz-transition-property: background, color, height, line-height;
    -o-transition-property: background, color, height, line-height;
    transition-property:  background, color, height, line-height;
}
.mobile-nav:hover,
.mobile-nav.active{
    background: #fff;
    color: #777;
}
.mobile-nav:active{
    -webkit-box-shadow: 0 0 35px rgba(0,0,0,.05) inset;
    -moz-box-shadow: 0 0 35px rgba(0,0,0,.05) inset;
    box-shadow: 0 0 35px rgba(0,0,0,.05) inset;
}
.no-js .mobile-nav{
    width: 55px;
    height: 55px;
}
.mobile-on .mobile-nav{
    display: table;
}
.mobile-nav .fa{
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 21px;
}

/* Menu sub multi */

.mobile-on .mn-has-multi{
    width: auto;
    padding: 0;
    text-align: left;
    white-space: normal;
    
}
.mobile-on .mn-sub{
    width: 100%;
    float: none;
    position: relative;
    overflow-x: hidden;
}

/*
 * Dark panel style
 */

.main-nav.dark{
    background-color: rgba(10,10,10, .95);
    
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow:  none;
}
.main-nav.dark .inner-nav ul > li > a{
    color: rgba(255,255,255, .7);
}
.main-nav.dark .inner-nav ul > li > a:hover,
.main-nav.dark .inner-nav ul > li > a.active{
    color: #fff;
}

.main-nav.dark .mobile-nav{
    background-color: transparent;
    border-color: transparent;
    color: rgba(255,255,255, .9);
}
.main-nav.dark .mobile-nav:hover,
.main-nav.dark .mobile-nav.active{
    color: #fff;
}

.main-nav.mobile-on.dark .inner-nav{
    border-top: 1px solid rgba(100,100,100, .4);
}

.main-nav.dark .logo,
.main-nav.dark a.logo:hover{
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(255,255,255, .9);
}

.main-nav.dark .mn-sub{    
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow:  none;
}

.main-nav.dark .mn-soc-link{
    border: 1px solid rgba(255,255,255, .2);
}
.main-nav.dark .mn-soc-link:hover{
    border: 1px solid rgba(255,255,255, .3);
}

/*
 * Transparent panel style
 */

.main-nav.transparent{
    background: transparent !important;
}
.main-nav.js-transparent{
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}


/* 
 * Centered menu 
 */

.mn-centered{
    text-align: center;
}
.mn-centered .inner-nav{
    float: none;
}
.mn-centered .inner-nav > ul{
    float: none;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}
.mn-centered .inner-nav > ul > li:first-child{
    margin-left: 0;
}
.mn-centered .mobile-nav{
    float: none;
    margin-left: auto;
    margin-right: auto;
}

/*
 * Menu social links
 */

.mn-soc-link{
    width: 34px;
    height: 34px;
    line-height: 33px;
    text-align: center;
    font-size: 12px;
    
    display: inline-block;
    border: 1px solid rgba(0,0,0, .1);
    
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.mn-soc-link:hover{
    border: 1px solid rgba(0,0,0, .25);
}
.main-nav .tooltip{
    font-size: 12x;
    text-transform: none;
    letter-spacing: 0;
}


/* ==============================
   Fullscreen menu
   ============================== */
 
 .fm-logo-wrap{
    position: absolute;
    top: 30px;
    left: 30px;
    height: 54px;
    z-index: 1030;
}
.fm-logo-wrap .logo{
    display: block;
    max-width: 200px;
    position: relative;
    top: 50%;  
    
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
} 
  
.fm-button{
    display: block;
    position: fixed;
    top: 30px;
    right: 30px;
    width: 54px;
    height: 54px;
    background: #111;
    opacity: .85;
    text-indent: -12345px;
    z-index: 1030;
    
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    
    -webkit-transition: opacity 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: opacity 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: opacity 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: opacity 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: opacity 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.fm-button:hover{
    opacity: 1;
}
.fm-button span{
    display: block;
    width: 22px;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -11px;
    
    -webkit-transition: all 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.fm-button span:before,
.fm-button span:after{
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    top: -6px;
    left: 0;

    -webkit-transition: top 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), bottom 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), -webkit-transform 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000) 0.07s;
    -moz-transition: top 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), bottom 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), -moz-transform 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000) 0.07s;
    -o-transition: top 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), bottom 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), -o-transform 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000) 0.07s;
    -ms-transition: top 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), bottom 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), -ms-transform 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000) 0.07s;
    transition: top 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), bottom 0.07s cubic-bezier(0.000, 0.000, 0.580, 1.000), transform 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000) 0.07s;
}
.fm-button span:after{
    top: auto;
    bottom: -6px;
}

/* Active menu button */

.fm-button.active{
    background: #222;
    z-index: 2001;
}
.fm-button.active span{
    background: transparent;
}
.fm-button.active span:before{ 
    top: 0;   
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.fm-button.active span:after{    
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg); 
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* Menu wrapper */

.fm-wrapper{
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 50px 30px;
    overflow-x: hidden;
    overflow-y: auto;
    background: rgba(10,10,10, .97);
    z-index: 2000;
    
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.fm-wrapper-sub{
    display: table;
    width: 100%;
    height: 100%;
    opacity: 0;
    
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -o-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    
    -webkit-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.fm-wrapper-sub.js-active{
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.fm-wrapper-sub-sub{
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.fm-menu-links,
.fm-menu-links ul{
    margin: 0;
    padding: 10px 0;
    line-height: 1.3;
    text-align: center;
}
.fm-menu-links ul.fm-sub{
    padding: 0;
}
.fm-menu-links a{
    display: block;
    position: relative;
    margin: 0;
    padding: 15px 20px;
    color: #999;
    font-size: 17px;
    letter-spacing: 2px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    
    -webkit-tap-highlight-color: rgba(255,255,255, .1); 
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    
    z-index: 11;
}
.no-touch .fm-menu-links a:hover,
.fm-menu-links a:active,
.fm-menu-links li.js-opened > a{
    color: #d5d5d5;
    text-decoration: none;
}
.fm-menu-links li{
    margin: 0;
    padding: 0;
    position: relative;
}

.fm-menu-links li > a:active,
.fm-menu-links li.active > a,
.fm-menu-links li a.active{
    color: #f5f5f5;
}
.fm-menu-links li.js-opened:before{
    display: none;
}
.no-touch .fm-menu-links li.js-opened > a:hover,
.fm-menu-links li.js-opened > a:active{
    color: #fff;
}

.fm-has-sub{
    
}
.fm-has-sub .fa{
    font-size: 14px;
}
.js-opened > .fm-has-sub > .fa{
    color: #fff;
}
.fm-sub{
    display: none;
    opacity: 0;
    position: relative;
}
.fm-sub > li > a{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
}

.fm-menu-links li.js-opened .fm-sub{
    opacity: 1;
}
.fm-search{
    width: 320px;
}
.fm-search input.search-field{
    background: transparent;
    color: #555;
    text-align: center;
    border: none;
    border-bottom: 1px solid rgba(255,255,255, .2);
}
.fm-search input.search-field:hover{
    border: none;
    border-bottom: 1px solid rgba(255,255,255, .4);
}
.fm-search input.search-field:focus{
    color: #999;
    border-color: rgba(255,255,255, .7);
}
.fm-search .search-button:hover{
    color: #fff;
}


/* Social links */

.fm-social-links{
    padding: 10px;
    font-size: 14px;
    text-align: center;
}
.fm-social-links > a{
    display: inline-block;
    margin: 0 2px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border: 1px solid rgba(255,255,255, .15);
    text-align: center;    
    text-decoration: none;
    color: #555;
    
    -webkir-border-radius: 50%; 
    -moz-border-radius: 50%; 
    border-radius: 50%; 
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.fm-social-links > a:hover{
    border-color: rgba(255,255,255, .3);
    color: #999;
}


/* ==============================
   Sections styles
   ============================== */

/*
 * Common
 */

.page-section,
.small-section{
    width: 100%;
    display: block;    
    position: relative;
    overflow: hidden;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
    padding: 120px 0;
}

.bg-pos-top {
    background-position: top center !important;
}
.bg-pos-bottom {
    background-position: bottom center !important;
}
.bg-pos-top-right {
    background-position: top right !important;
}
.small-section{
    padding: 55px 0;
}
.small-title{
    padding: 0;
    margin: 0 0 30px 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.3em;
}
.section-icon{
    margin-bottom: 7px;
    text-align: center;
    font-size: 32px;
}
.small-title .serif{
    text-transform: none;
    font-weight: 400;
}
.small-title.white{
    color: #fff;
}
.bg-scroll{
    background-attachment: scroll !important;
}
.bg-pattern{
    background-repeat: repeat;
    background-size: auto;
}
.bg-footer {background-color: #ECECEC;}
.bg-orange{ background-color: #f47631 !important; }
.bg-yellow{  background-color: #ffce02 !important; }
.bg-cyan{ background-color: #27c5c3 !important; }
.bg-green{ background-color: #58bf44 !important; }
.bg-blue{ background-color: #182E46 !important; }
.bg-purple{ background-color: #375acc !important; }
.bg-red{ background-color: #e13939 !important; }
.bg-light-gray {background-color: #ECECEC !important;}
.section-title {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 500;
    text-transform: none;
    text-align: center;
    letter-spacing: 0em;
    line-height: 1.4;
}
.page-title {
    font-size: 35px;
    font-weight: 500;
    text-transform: none;
    text-align: center;
    letter-spacing: 0em;
    line-height: 1.4;
    color: #fff;
}
.section-more{
    margin-top: 7px;
    color: #999;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.4em;
    text-decoration: none;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.section-more:hover{
    text-decoration: none;
    color: #000;
 
}
.section-more .fa{
    font-size: 12px;
}

.section-heading {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5em;
    line-height: 1.3;
}
.section-line{
    width: 55px;
    height: 1px;
    margin: 30px auto;
    background: #111;
}
.section-text{
    font-size: 15px;
    font-weight: 500;
    color: #313131;
    line-height: 1.7;
}
.section-text blockquote{
    margin-top: -5px;
}
.terms-text {
    text-align: justify;
    font-size: 15px;
    font-weight: 500;
    color: #313131;
    line-height: 1.7;
}
.terms-text p {
    margin-bottom: 15px;
}
.section-bot-image{
    margin: 140px 0 -140px;
    text-align: center;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0); 
}
.image-fullwidth img{
    width: 100%;
}
.section-text ul {
    margin-bottom: 20px;
}
.section-text ul li {
    font-size: 15px;
    font-weight: 600;
}
/* Breadcrumbs */

.mod-breadcrumbs{
    margin-top: 0px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-decoration: none;
    text-transform: none;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.mod-breadcrumbs span{
    opacity: .65;
}
.mod-breadcrumbs a{
    text-decoration: none;

}
.mod-breadcrumbs a:hover{
    text-decoration: none; 
}
.mod-breadcrumbs .fa{
    font-size: 12px;
}
.mod-breadcrumbs-mini{
    text-align: left;
}

.home-bot-image{
    width: 100%;
    position: absolute;
    bottom:0;
    left: 0;
    text-align: center;
}

.fixed-height-large,
.fixed-height-medium,
.fixed-height-small{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fixed-height-large{
    min-height: 800px;
}
.fixed-height-medium{
    min-height: 760px;
}
.fixed-height-small{
    min-height: 650px;
}
.abroad-pages-height {
    min-height: 760px;
}
.leftContent {
    position: absolute;
    bottom: -100px;
    left: 120px;
    max-width: 350px;
    color: #000000;
}
.leftContent .title {
    color: #EC2C2A;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.rightContent {
    position: absolute;
    top: 185px;
    right: 120px;
    max-width: 350px;
    color: #000000;
}
.rightContent .title {
    color: #EC2C2A;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
/*
 * Split section
 */

 .split-section{
    width: 100%;
    display: block;    
    position: relative;
    overflow: hidden;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.split-section-headings{    
    width: 50%;
    position: relative;
    overflow: hidden;
    text-align: center;
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.ssh-table{
    width: 100%;
    min-height: 500px;
    display: table;
}
.ssh-cell{
    display: table-cell;    
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    height: 100vh;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}

.split-section-content{
    width: 50%;
    position: relative;
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
}
.split-section-wrapper{
    padding: 0 50px;
}

/*
 * Works full view
 */

.work-full{
    display: none;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10000;
}
.work-full-load{
    padding-top: 60px;
}
.work-loader{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10001;
}
.work-loader:after{
    display: block;
    content: " ";
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -8px;
    background: url(../images/loader.gif) no-repeat;
}
/* ==============================
   Banner section
   ============================== */

.banner-section{
    overflow: visible;
    border-bottom: 1px solid transparent;
}
.banner-heading{
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: rgba(255,255,255, 0.9);
}

.banner-decription{
    margin-bottom: 35px;
    font-size: 15px;    
    font-weight: 300;
    color: rgba(255,255,255, .7);
}

.banner-image{
    margin: -40px 0 0 0;
    text-align: right;
}
.banner-image img{
    
}


/* ==============================
   Testimonial
   ============================== */

.testimonial{
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0;
    opacity: .85;
}
.testimonial p{
    font-size: 18px;
}
.testimonial-author{

}

/*
 * Logotypes
 */

.logo-item{
    height: 80px;
    text-align: center;
    opacity: .75;
    
    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    
}
.logo-item:hover{
    opacity: 1;
}
.logo-item img{
    position: relative;
    top: 50%;
    
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* React scroll component horizontally on button click */
.button-contianer {
    display: flex;
    justify-content: space-between;
}
  
.img-container {
    display: flex;
    overflow: hidden;
    width: 95%;
    padding: 10px 0;
}

/* ==============================
   Footer
   ============================== */
.footer a{
    color: #888;
    text-decoration: none;
    
    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.footer a:hover{
    color: #111;
     text-decoration: underline;
}
.footer-copy{
    color: #182E46;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.5px;
}
.footer-made{
    font-size: 11px;
    color: #999;
}
.footer-text {
    border-top: 1px solid #333F51;
}
.social-icons ul {
    margin: 0;
    padding: 0;
}
.social-icons ul li {
    display: inline-block;
    list-style: none;
    padding: 0 6px;
}
.social-icons ul li:first-child {
    padding-left: 0;
}
a.link-to-top{
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: rgba(0,0,0, .2);
    font-size: 48px;
}

/*
 * Pagination
 */

.pagination{
    font-size: 13px;
    font-family: "Lato", sans-serif;
}
.pagination a{
    display: inline-block;
    min-width: 30px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    padding: 7px 14px;
    border: 1px solid #eaeaea;
    color: #999;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.pagination .fa{
    margin-top: 1px;
    font-size: 16px;
}
.pagination a:hover{
    text-decoration: none;
    border-color: #bbb;
    color: #444;
}
.pagination a.active,
.pagination a.active:hover{
    border-color: #bbb;
    color: #444;
    cursor: default;
}
.pagination a.no-active,
.pagination a.no-active:hover{
    border-color: #efefef;
    color: #bbb;
    cursor: default;
}

/*
 * Sidebar
 */

.sidebar{
    margin-top: 10px;
}
.widget{
    margin-bottom: 60px;
}
.widget .img-left{
    margin: 0 10px 10px 0;
}
.widget .img-right{
    margin: 0 0 10px 10px;
}
.widget-title{
    margin-bottom: 20px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: #777;
    border-bottom: 1px solid #ccc;
}
.widget-body{
    font-size: 13px;
    color: #777;
}
.widget-text{
    line-height: 1.7;
}


/* Search widget */

.search-wrap{
    position: relative;
}
.search-field{
    width: 100% !important;
    height: 40px !important;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.search-button{
    width: 42px;
    height: 40px;
    line-height: 38px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    right: 1px;    
    overflow: hidden;
    background: transparent;
    border: none;
    outline: none;
    color: #999;
    font-size: 14px;
}

.search-button:hover{
    color: #111;
}
.search-button:hover + .search-field{
    border-color: #ccc;
}


/* Widget menu */

.widget-menu{
    font-size: 13px;
}
.widget-menu li{
    padding: 5px 0;
    
}
.widget-menu li a{
    color: #555;
    text-decoration: none;
    padding-bottom: 2px;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    
    -webkit-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.widget-menu li a:hover,
.widget-menu li a.active{
    color: #999;
}
.widget-menu li small{
    font-size: 11px;
    color: #aaa; 
}


/* Widget tags */

.tags{
    
}
.tags a{
    display: inline-block;
    margin: 0 2px 5px 0;
    padding: 5px 7px;
    border: 1px solid #e9e9e9;
    color: #777;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.tags a:hover{
    text-decoration: none;
    border-color: #333;
    color: #111;
}
/* Posts */
.widget-posts{
    color: #aaa;
}
.widget-posts li{
    font-size: 12px;
    padding: 12px 0;
    border-bottom: 1px dotted #eaeaea;
}
.widget-posts li a{
    font-size: 13px;
    display: block;
    color: #555;
    text-decoration: none;
    
    -webkit-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.widget-posts li a:hover{
    color: #999;
}
.widget-posts-img{
    float: left;
    margin: 0 7px 0 0;
}
.widget-posts-descr{
    overflow: hidden;
}


/* ==============================
   Shortcodes
   ============================== */

.margin-block{
    margin-bottom: 30px;
}


/*
 * Owl Carousel
 */

.owl-carousel{
    overflow: hidden;
}
.owl-buttons{
    position: static;
}
.owl-prev,
.owl-next{
    opacity: 0;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    width: 105px;
    height: 105px;
    line-height: 105px;
    font-size: 16px;
    text-align: center;
    color: #111;
    
    z-index: 6;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.owl-prev{
    left: -50px;
}
.owl-next{
    right: -50px;
}
.owl-prev:before,
.owl-next:before{
    content: "";
    display: block;
    width: 66%;
    height: 66%;
    position: absolute;
    top: 17px;
    left: 0;
    
    background: #fff;
    
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    
    -webkit-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    -moz-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.owl-next:before{
    left: auto;
    right: 0;
}
.owl-prev .fa,
.owl-next .fa{
    position: relative;
}
.owl-prev:hover,
.owl-next:hover{    
    -webkit-transition: 0 none !important;  
    -moz-transition: 0 none !important;  
     -ms-transition: 0 none !important;
    -o-transition: 0 none !important;    
    transition:0 none !important;  
}
.owl-prev:hover:before,
.owl-next:hover:before{
    opacity: 0.87;
}
.owl-prev:active:before,
.owl-next:active:before{    
    opacity: 0.6;
}
.owl-carousel:hover .owl-prev{
    opacity: 1;
    left: -35px;
}
.owl-carousel:hover .owl-next{
    opacity: 1;
    right: -35px;
}
.owl-carousel .owl-prev:focus{
    opacity: 1;
    left: -35px;
}
.owl-carousel .owl-next:focus{
    opacity: 1;
    right: -35px;
}
.owl-pagination{
    display: block;
    width: 100%;
    position: absolute;
    bottom: 30px;
    left: 0;
    text-align: center;
}
.owl-page{
    display: inline-block;
    padding: 6px 4px;
    position: relative;
}
.owl-page span{
    width: 6px;
    height: 6px;
    display: block;
    background: rgba(255,255,255, .7);
    position: relative;
    z-index: 1000 !important;
    
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    
    -webkit-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    -moz-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.owl-page:hover span{
    background: rgba(255,255,255, 1);
}
.owl-page.active span{    
    background: transparent;
    bordeR: 1px solid rgba(255,255,255, .8);
    
    -webkit-transform: scale(1.3333333);
    -moz-transform: scale(1.3333333);
    -o-transform: scale(1.3333333);
    -ms-transform: scale(1.3333333);
    transform: scale(1.3333333);
}

.item-carousel .owl-prev{
    left: -125px;
}
.item-carousel .owl-next{
    right: -125px;
}
.item-carousel.owl-carousel:hover .owl-prev,
.item-carousel.owl-carousel .owl-prev:focus,
.small-item-carousel.owl-carousel:hover .owl-prev,
.small-item-carousel.owl-carousel .owl-prev:focus{
    opacity: 0;
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
}
.item-carousel.owl-carousel:hover .owl-next,
.item-carousel.owl-carousel .owl-next:focus,
.small-item-carousel.owl-carousel:hover .owl-next,
.small-item-carousel.owl-carousel .owl-next:focus{
    opacity: 0;
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
}

.item-carousel{
    margin-bottom: -50px;
    padding-bottom: 50px;
}
.item-carousel .owl-pagination{
    bottom: 0;
}

.work-full-slider .owl-pagination,
.content-slider .owl-pagination{
    bottom: 15px;
}

.single-carousel,
.small-item-carousel{
    margin-bottom: 35px;
}
.single-carousel .owl-pagination,
.small-item-carousel .owl-pagination{
    position: relative;
    left: 0;
    top: 35px;
    margin: 0;
}
.single-carousel.black .owl-page span,
.small-item-carousel.black .owl-page span{
    background: rgba(0,0,0, .15);
    
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.single-carousel.black .owl-page:hover span,
.small-item-carousel.black .owl-page:hover span{
    background: rgba(0,0,0, .4);
}
.single-carousel.black .owl-page.active span,
.small-item-carousel.black .owl-page.active span{
    background: rgba(0,0,0, .4);
}

.single-carousel.black .owl-prev,
.single-carousel.black .owl-next,
.small-item-carousel.black .owl-prev,
.small-item-carousel.black .owl-next{
    color: rgba(0,0,0, .2);
    text-shadow: none;
    
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.single-carousel.black .owl-prev:hover,
.single-carousel.black .owl-next:hover,
.small-item-carousel.black .owl-prev:hover,
.small-item-carousel.black .owl-next:hover{
    color: rgba(0,0,0, .35);
}
.single-carousel .owl-prev,
.small-item-carousel .owl-prev{
    left: -125px;
}
.single-carousel .owl-next,
.small-item-carousel .owl-next{
    right: -125px;
}
section:hover .single-carousel .owl-prev,
section:hover .small-item-carousel .owl-prev{
    opacity: 1;
    left: -80px;
}
section:hover .single-carousel .owl-next,
section:hover .small-item-carousel .owl-next{
    opacity: 1;
    right: -80px;
}

.black-arrows .owl-prev,
.black-arrows .owl-next{
    color: #fff;
}
.black-arrows .owl-prev:before,
.black-arrows .owl-next:before{
        background: rgba(0,0,0, 1);
}

/*
 * Alert messages
 */

.alert{
    margin: 0 auto 10px; 
    border: none;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.success{
    border-color: #a9e2c2;
    color: #36a367;
}
.info{
    border-color: #9ecdfc;
    color: #297ad3;
}
.notice{
    border-color: #f9ce75;
    color: #e9a00a;
}
.error{
    border-color: #ffbbbb;
    color: #e33131;
}

/*
 * Font icons examples
 */

.fa-examples{
    color: #555;
    margin-left: 1px;
}
.fa-examples > div{
    padding: 0;
    border: 1px solid #e5e5e5;
    margin: -1px 0 0 -1px;
    font-size: 13px;
}
.fa-examples > div:hover{
    background: #f0f0f0;
    color: #000;
}
.fa-examples > div > i{
    display: inline-block;
    margin-right: 5px;
    min-width: 40px;
    min-height: 40px;
    border-right: 1px solid #f1f1f1;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
}
.fa-examples > div > .muted{
    margin-left: 5px;
    color: #999;
    font-size: 12px;
}
.fa-sm{
    width: 20px;
    font-size: 14px;
}
.db-icon{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
}
.db-title{
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
}
.db-descr{
    display: block;
    font-size: 11px;
    color: #777;
    font-weight: 400;
}
.banner-title {
    font-size: 16px;
    text-transform: uppercase;
    color: #B9943C;
    font-weight: 500;
    letter-spacing: -0.5px;
}
.banner-desc {
    color: #182E46;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
}
.succcess-msg {
    color: #182E46;
    font-weight: 600;
    font-size: 24px;
}
.universities ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
}
.universities ul li {
    list-style: none;
    display: inline;
    margin: 0 15px;
    transition: 0.3s;
}
.universities ul li img {
    transition: 0.3s;
}
.universities ul li img:hover {
    margin-top: -8px;
}
.image-rounded img {
    border-radius: 8px;
}
/* Logo */
 .sp-logo-wrap{
}
.sp-logo-wrap .logo{
    display: block;
    padding: 30px;
    text-align: center;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
} 
.sp-logo-wrap .logo img{
    max-width: 100%;
}

/* Menu */

.sp-wrapper{
    
}
.sp-menu-links,
.sp-menu-links ul{
    margin: 0;
    padding: 10px 0;
    line-height: 1.3;
    text-align: center;
}
.sp-menu-links ul.sp-sub{
    padding: 0;
}
.sp-menu-links a{
    display: block;
    position: relative;
    margin: 0;
    padding: 15px 20px;
    color: #999;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    
    -webkit-tap-highlight-color: rgba(255,255,255, .1); 
        
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    
    z-index: 11;
}
.no-touch .sp-menu-links a:hover,
.sp-menu-links a:active,
.sp-menu-links li.js-opened > a{
    color: #d5d5d5;
    text-decoration: none;
}
.no-touch .sp-menu-links a:hover{
    background-color: rgba(255,255,255, .05);
}
.sp-menu-links li{
    margin: 0;
    padding: 0;
    position: relative;
}

.sp-menu-links li > a:active,
.sp-menu-links li a.active{
    color: #f5f5f5;
}
.sp-menu-links li.js-opened:before{
    display: none;
}
.no-touch .sp-menu-links li.js-opened > a:hover,
.sp-menu-links li.js-opened > a:active{
    color: #fff;
}

.sp-has-sub{
    
}
.sp-has-sub .fa{
    font-size: 14px;
}
.js-opened > .sp-has-sub > .fa{
    color: #fff;
}
.sp-sub{
    display: none;
    opacity: 0;
    position: relative;
}
.sp-sub > li > a{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
}

.sp-menu-links li.js-opened .sp-sub{
    opacity: 1;
}
.sp-search{
    width: 100%;
}
.sp-search input.search-field{
    background: transparent;
    color: #555;
    text-align: center;
    border: none;
    border-bottom: 1px solid rgba(255,255,255, .2);
}
.sp-search input.search-field:hover{
    border: none;
    border-bottom: 1px solid rgba(255,255,255, .4);
}
.sp-search input.search-field:focus{
    color: #999;
    border-color: rgba(255,255,255, .7);
}
.sp-search .search-button:hover{
    color: #fff;
}


/* Social links */

.sp-social-links{
    position: fixed;
    bottom: 0;
    left: 0;
    width: inherit;
    padding: 10px 10px 30px 10px;
    font-size: 14px;
    text-align: center;
    background: transparent;
    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
    z-index: 1032;
}
.sp-social-links > a{
    display: inline-block;
    margin: 0 2px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border: 1px solid rgba(255,255,255, .15);
    text-align: center;    
    text-decoration: none;
    color: #555;
    
    -webkir-border-radius: 50%; 
    -moz-border-radius: 50%; 
    border-radius: 50%; 
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.sp-social-links > a:hover{
    border-color: rgba(255,255,255, .3);
    color: #999;
}

/* White side panel */

.side-panel.white{
    color: rgba(0,0,0, .65) !important;
    background: #fafafa;
    border-right: 1px solid rgba(0,0,0, .07);
}
.side-panel.white .sp-close-button{
    border: 1px solid rgba(0,0,0, .5);
}

.side-panel.white .sp-close-button:before{
    background: #000;
}
.side-panel.white .sp-close-button:after{
    background: #000;
}
.side-panel.white .sp-menu-links a{
    color: #777;
    -webkit-tap-highlight-color: rgba(0,0,0, .05); 
}
.side-panel.white .sp-menu-links li{
    border-top: 1px solid rgba(0,0,0, .04);
}
.side-panel.white .sp-menu-links li:last-child{
    border-bottom: 1px solid rgba(0,0,0, .04);
}

.no-touch .side-panel.white .sp-menu-links a:hover,
.side-panel.white .sp-menu-links a:active,
.side-panel.white .sp-menu-links li.js-opened > a{
    color: #111;
    text-decoration: none;
}
.no-touch .side-panel.white .sp-menu-links a:hover{
    background-color: rgba(0,0,0, .03);
}

.side-panel.white .sp-menu-links li > a:active,
.side-panel.white .sp-menu-links li a.active{
    color: #111;
}
.no-touch .side-panel.white .sp-menu-links li.js-opened > a:hover,
.side-panel.white .sp-menu-links li.js-opened > a:active{
    color: #111;
}

.side-panel.white .js-opened > .sp-has-sub > .fa{
    color: #333;
}
.side-panel.white .sp-sub > li{
    border: none!important;
}

.side-panel.white .sp-social-links > a{
    border: 1px solid rgba(0,0,0, .15);
    color: rgba(0,0,0, .4);
}
.side-panel.white .sp-social-links > a:hover{
    border-color: rgba(0,0,0, .3);
    color: rgba(0,0,0, .75);
}

/* Photo Tilt effect */

.tilt-wrap{
    width: 100%;
    position: relative;
}
.tilt{
    overflow: visible;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
} 
.tilt__back, .tilt__front{
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
} 
.tilt__back{
    position: relative;
} 
.tilt__front{
    position: absolute;
    top: 0;
    left: 0;
}

/*
 * Coming soon page
 */

.countdown{
    display: inline-block;
}
.countdown > li{
    float: left;
    margin: 0 30px;
}
.countdown-number{
    position: relative;
    display: block;
    width: 94px;
    height: 94px;
    color: #111;
    font-size: 36px;
    text-align: center;
    line-height: 84px;
}
.countdown-number > span{
    position: relative;
    z-index: 2;
}
.countdown-number > b{
    display: block;
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;
    color: #999;
    z-index: 2;
}
.countdown-number:before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255, .95);
    
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
    z-index: 1;
}

/* Dark Timer */

.countdown.dark .countdown-number{
    color: #fff;
}
.countdown.dark .countdown-number > b{
    color: #aaa;
}
.countdown.dark .countdown-number:before{
    background: rgba(0,0,0, .8);
}


/* ==============================
   Skip to Content Link
   ============================== */
  
.skip-to-content{
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 20px 40px;
    color: #ffffff;
    background: #111;
    -webkit-transform: translateY(-150%);
    -moz-transform: translateY(-150%);
    -ms-transform: translateY(-150%);
    transform: translateY(-150%);
    z-index:100000;
}
.skip-to-content:hover,
.skip-to-content:focus,
.skip-to-content.focus-visible{
    color: #ffffff;
    opacity: 1;
    background: #000;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}


/* ==============================
   Improve the readability
   ============================== */

.section-text, 
.text,
.banner-decription,
.alt-features-descr,
.post-prev-text,
.ci-text,
.team-item-detail,
.alt-service-item,
.benefits-descr,
.features-descr,
.blog-item-body{
	font-weight: 400;
}
.blog-item-body{
	color: #555555;
}

/* ==============================
   Morphext
   ============================== */
.pr-0 {
    padding-right: 0 !important;
}
.pl-0 {
    padding-left: 0 !important;
}
.morphext > .animated {
    display: inline-block;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}
.footer-menu ul {
    margin: 0;
    padding: 0;
}
.footer-menu ul li {
    list-style: none;
    display: inline-block;
    padding: 0 15px;
}
.footer-menu ul li:first-child {
    padding-left: 0;
}
.footer-menu ul li:last-child {
    padding-right: 0;
}
.footer-menu ul li a {
    color: #182E46;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -0.5px;
}
.footer-widget {
    color: #182E46;
    font-size: 15px;
}
.footer-widget a {
    color: #182E46;
}
.footer-widget .title {
    font-size: 17px;
    font-weight: 700;
    color: #B9943C;
    text-transform: uppercase;
}
.footer-widget .map-link {
    border: none;
    background-color: transparent;
    padding: 0;
    margin-top: 10px;
    display: block;
}
.footer-widget .map-link svg {
    max-width: 22px;
}
.footer-widget .map-link span {
    text-decoration: underline;
    padding-left: 5px;
}
.footer-widget h5 {
    font-size: 15px;
    color: #182E46;
    font-weight: 600;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.buttonlink {
    border: none;
    background-color: transparent;
    padding: 0;
    color: #B9943C;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px
}
.contactContainer {
    background: #FFFFFF;
    box-shadow: 0px 9px 54px rgba(32, 32, 32, 0.1);
    border-radius: 10px;
    padding: 30px;
}
.contactFormContainer {
    padding: 0 30px;
}
.contactFormContainer .form-group {
    margin-bottom: 15px;
}
.contactFormContainer .form-control {
    background-color: #F9F9F9;
    border-radius: 30px;
}
.contactFormContainer textarea {
    padding: 20px;
    border-radius: 20px !important;
}
.contactFormContainer a {
    color: #0167C6;
}
.contactFormContainer label {
    color: #767676;
}
.services-item{
    padding: 30px;
    text-align: center;
    position: relative;
}
.services-icon{
    margin-bottom: 35px;
}
.services-title{
    margin: 15px 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0m;
}
.services-descr {
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.86
}
.services-item .box {
    width: 80px;
    height: 80px;
    background: #D20521;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 80px;
    animation: animate 2s linear infinite;
    cursor: pointer;
    margin: 0 auto;
}
.services-item .box.dark {
    background: #364B5D;
    animation: animatedark 2s linear infinite;
}
.services-item .box i {
    color: #fff;
    font-size: 60px;
}
@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 #D20521;
    }
    40% {
        box-shadow: 0 0 0 40px rgba(255, 26, 67, 0);
    }
    80% {
        box-shadow: 0 0 0 40px rgba(255, 206, 67, 0);
    }
    100% {
        box-shadow: 0 0 0 rgba(255, 206, 67, 0);
    }
}
.arrow-one {
    position: absolute;
    left: -80px;
    top: 60px;
}
.arrow-two {
    position: absolute;
    right: -80px;
    top: 60px;
}

.dtskill-services-item{
    padding: 30px;
    text-align: center;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    position: relative;
}
.dtskill-services-item:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
.dtskill-services-item:hover .open-content {
    opacity: 0;
}
.hover-content {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    padding: 20px 23px 55px;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.dtskill-services-item:hover .hover-content {
    opacity: 1;
}
.hover-content .dtskill-services-title {
    font-size: 23px;
    color: #e41919 !important;
    letter-spacing: -1px;
}
.dtskill-services-item:hover .hover-content .dtskill-services-title {
    font-size: 24px;
    font-weight: 700;
    color: #2A354E;
}
.dtskill-services-icon{
    margin-bottom: 20px;
    text-align: left;
    max-width: 80px;
}
.dtskill-services-icon img {
    max-width: 80px;
}
.dtskill-services-title{
    margin: 10px 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0;
}
.dtskill-services-descr {
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.86
}
.dtskill-shadow {
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
}
/* ==============================
   Team
   ============================== */
.dtskill-item{
    transition: all .3s ease-in-out;
    margin: 50px 0
}
.dtskill-slider .slick-slide {
    margin: 0 20px;
}
.dtskill-slider .dtskill-item:hover {
    transform: scale(1.1);
    position: relative;
    overflow: visible;
    z-index: 999;
}
.dtskill-slider .slick-prev {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    left: 45% !important;
    margin-left: 10px;
    color: #CA924C;
    border: 2px solid #CA924C;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.dtskill-slider .slick-prev:hover {
    background-color: #CA924C;
}
.dtskill-slider .slick-prev:hover::before {
    color: #fff;
}
.dtskill-slider .slick-prev::before {
    content: "\f104";
    color: #CA924C;
    font-size: 30px;
    font-family: 'FontAwesome';
}
.dtskill-slider .slick-next:hover {
    background-color: #CA924C;
}
.dtskill-slider .slick-next:hover::before {
    color: #fff;
}
.dtskill-slider .slick-next {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    right: 45% !important;
    margin-right: 10px;
    color: #CA924C;
    border: 2px solid #CA924C;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.dtskill-slider .slick-next::before {
    content: "\f105";
    color: #CA924C;
    font-size: 30px;
    font-family: 'FontAwesome';
}
.dtskill-slider .slick-prev, .dtskill-slider .slick-next {
    bottom: -20%;
    top: auto;
}
.dtskill-slider .dtskill-item:hover .dtskill-item-image{ 
    transform-origin: top;
}
.dtskill-item-image{
    position: relative;
    overflow: hidden;
}
.dtskill-item-image img{
    width: 100%;
}
.dtskill-item-image:after{
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    
    z-index: 1;
    
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.dtskill-item-detail{
    opacity: 0;
    width: 100%;
    position: absolute;
    bottom: -60px;
    left: 0;
    padding: 10px 40px;
    text-align: center;
    z-index: 2;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.dtskill-item-detail .divider {
    background-color: #fff;
    width: 90px;
    height: 4px;
    margin: 0 auto 15px;
}
.dtskill-item-detail h4 {
    text-transform: none;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 15px;
}
.dtskill-item-detail p {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
}
.dtskill-item-detail .font-xs {
    color: #fff;
    font-size: 11px;
}
.dtskill-item-descr{
    color: #777;
    text-align:center;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
/* Hover state */
.no-mobile .dtskill-item:hover .dtskill-item-image:after{
    background-image: url(../images/service-hover.png);
    background-repeat: no-repeat;
    width: 100%;
    background-size: contain;
    background-attachment: scroll;

}
.no-mobile .dtskill-item:hover .dtskill-item-detail{
    opacity: 1;
}
.no-mobile .dtskill-item:hover .dtskill-item-descr{    
    color: #111;
}
.no-mobile .team-social-links a:hover{
    color: rgba(0,0,0, .75);
}

/* Mobile touch */
.dtskill-item.js-active .dtskill-item-image:after{
    background: rgba(255,255,255, .93);
}
.dtskill-item.js-active .dtskill-item-detail{
    opacity: 1;
}
.dtskill-item.js-active .dtskill-item-descr{    
    color: #111;
}
.dtskill-item.js-active .team-social-links a:hover{
    color: rgba(0,0,0, .75);
}
.digitalFuture {
    display: flex;
    align-items: center;
    padding: 60px 0;
}
.digitalFuture-icon {
    padding: 15px;
    margin-right: 20px;
}
.digitalFuture-icon .box {
    width: 100px;
    height: 100px;
    background: #D20521;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 100px;
    animation: animate 2s linear infinite;
    cursor: pointer;
    margin: 0 auto;
}
.digitalFuture-icon .box.dark {
    background: #364B5D;
    animation: animatedark 2s linear infinite;
}
.digitalFuture-icon .box img {
    max-width: 50px;
}
@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 #D20521;
    }
    40% {
        box-shadow: 0 0 0 40px rgba(255, 26, 67, 0);
    }
    80% {
        box-shadow: 0 0 0 40px rgba(255, 206, 67, 0);
    }
    100% {
        box-shadow: 0 0 0 rgba(255, 206, 67, 0);
    }
}
@keyframes animatedark {
    0% {
        box-shadow: 0 0 0 0 #364B5D;
    }
    40% {
        box-shadow: 0 0 0 40px rgba(255, 26, 67, 0);
    }
    80% {
        box-shadow: 0 0 0 40px rgba(255, 206, 67, 0);
    }
    100% {
        box-shadow: 0 0 0 rgba(255, 206, 67, 0);
    }
}
.list-style ul { padding-left: 25px; list-style:none; }
.list-style ul li {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 400;
    color: #767676;
    line-height: 1.7;
    padding: 5px 0;
}
.list-style ul li:before {    
    font-family: 'FontAwesome';
    content: '\f058';
    margin:0 10px 0 -23px;
    color: #D80621;
}
.contact-form-container label {
    font-size: 14px;
    color: #000F2F;
    font-weight: 500;
}
.contact-form-container .req {
    color: #FF0000;
}
.contact-form-container .form-group {
    margin-bottom: 20px;
}
.contact-form-container .form-control {
    background: #F9F9F9;
    border: 2px solid #EAE9E9 !important;
    border-radius: 8px !important;
    font-size: 15px !important;
    padding: 10px 20px;
    min-height: 40px;
}
.contact-form-container .form-control:focus {
    box-shadow: none;
    outline: none;
    border: 2px solid #0057FF;
}
.contact-form-container select {

  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 7px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.3em 3.5em 0.3em 1em;
  margin: 0; 
  border: 1px solid #EAE9E9;     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
    height: 40px;
    width: 94%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}

.contact-form-container  select.classic {
  background-image:
    linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%),
    linear-gradient(to right, #D80621, #D80621);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

.contact-form-container select.classic:focus {
  background-image:
    linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}
.file-drop-area {
  position: relative;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  background: rgba(226, 236, 255, 0.8);
    border: 1.5px dashed rgba(0, 87, 255, 0.3);
    border-radius: 7px;
  transition: 0.2s;
}
.file-drop-area.is-active {
  background-color: rgba(255, 255, 255, 0.05);
}

.fake-btn {
  flex-shrink: 0;
  border-bottom: 1px solid #0A5DFF;
  color: #0A5DFF;
  margin-left: 6px;
  font-size: 14px;
  text-transform: none;
}

.file-msg {
  font-size: 14px;
  font-weight: 400;
  color: #C2C2C2;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.file-input:focus {
  outline: none;
}
.pics-service {
    background-color: #ECECEC;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    min-height: 355px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    -webkit-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.pics-service:hover {
    background-color: #fff;
}
.pics-service .icon img {
    width: 60px;
}
.pics-service h2 {
    color: #182E46;
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 30px;
}
.pics-service p {
    color: #182E46;
    font-size: 16px;
    margin-bottom: 0;
}
.pull-top {
    margin-top: -220px;
}
.pb-250 {
    padding-bottom: 250px;
}
.pb-160 {
    padding-bottom: 160px;;
}
.sticky-header {
    background-color: rgba(24, 46, 70, 0.95);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.sticky-header .nav-logo-wrap .logo {
    height: 65px;
    margin-top: 5px;
}
.pics-tabs {
    min-height: 40px !important;
    border: none !important;
    padding: 10px 0 !important;
    margin: 10px 0 !important;
    overflow: visible !important;
}
.pics-tabs .MuiTabs-scroller {
    display: flex;
    justify-content: center;
}
.pics-tabs .tab-single {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: flex;
    align-items: self-start;
    text-align: left;
    border-radius: 6px;
    padding: 4px 20px;
    color: #182E46;
    text-transform: uppercase;
    min-width: 170px;
    min-height: 28px;
    line-height: 25px;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    font-family: "Lato", sans-serif;
    margin: 5px 6px;
}
.pics-tabs .tab-single.Mui-selected {
    background-color: #182E46;
    border-bottom: 2px solid #182E46 !important;
    color: #fff;
    outline:  none !important;
}
.pics-tabs .MuiTabs-indicator {
    display: none !important;
}
.pics-tabs .tab-single.uk::after {
    background-image: url('../images/uk-sm.png');
    background-repeat: no-repeat;
    width: 25px;
    height: 17px;
    position: absolute;
    content: "";
    right: 10px;
    top: 8px;
}
.pics-tabs .tab-single.usa::after {
    background-image: url('../images/usa-sm.png');
    background-repeat: no-repeat;
    width: 25px;
    height: 17px;
    position: absolute;
    content: "";
    right: 10px;
    top: 8px;
}
.pics-tabs .tab-single.canada::after {
    background-image: url('../images/canada-sm.png');
    background-repeat: no-repeat;
    width: 25px;
    height: 17px;
    position: absolute;
    content: "";
    right: 10px;
    top: 8px;
}
.pics-tabs .tab-single.australia::after {
    background-image: url('../images/australia-sm.png');
    background-repeat: no-repeat;
    width: 25px;
    height: 17px;
    position: absolute;
    content: "";
    right: 10px;
    top: 8px;
}
.pics-tabs .tab-single.ireland::after {
    background-image: url('../images/ireland-sm.jpg');
    background-repeat: no-repeat;
    width: 25px;
    height: 17px;
    position: absolute;
    content: "";
    right: 10px;
    top: 8px;
}
.pics-tabs .tab-single.newzealand::after {
    background-image: url('../images/newzealand-sm.jpg');
    background-repeat: no-repeat;
    width: 25px;
    height: 17px;
    position: absolute;
    content: "";
    right: 10px;
    top: 8px;
}
.coming-soon-data {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 100%;
}
.univ-box {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 25px !important;
    margin-bottom: 20px !important;
    text-align: center;
    font-family: "Lato", sans-serif;
    width: 210px;
    height: 180px;
    -webkit-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.univ-box .icon {
    width: 150px;
    text-align: center;
    margin: 0 auto;
}
.univ-box:hover {
    margin-top: -5px;
}
.univ-box h4 {
    color: #182E46;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
}
.univ-box p {
    color: #182E46;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}
.small-section .css-1aquho2-MuiTabs-indicator {
    display: none;
}
.dblock {
    display: block;
}
.mblock {
    display: none
}
.buttonlink2 {
    border: none;
    background-color: transparent;
    padding: 0;
    color: #182E46 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-decoration: underline !important;
    text-transform: none !important;
}
.step-container {
    background-color: #fff;
    border-radius: 15px;
    padding: 30px 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-align: center;
    min-height: 210px;
    margin-bottom: 25px;
    position: relative;
    p {
        font-size: 14px;
        color: #8F8F8F;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 10px;
    }
}
.step-container .description {
    color: #313131;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    padding-left: 0px;
}
.step-container .description p {
    margin-bottom: 0;
}
.step-container .count {
    color: #182E46;
    font-size: 65px;
    font-weight: 700;
    padding-right: 30px;
}
.step-container h4 {
    color: #B9943C;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.step-container .buttonlink3{
    color: #B9943C;
    font-size: 12px;
    text-decoration: underline;
    text-transform: none;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
}
.university-list-bx {
    background-color: #fff;
    border-radius: 15px;
    border: none !important;
    width: 600px !important;
    padding: 0 !important;
    .closemodal {
        position:absolute;
        top: -10px;
        right: -10px;
        background-color: #fff;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }
}
.university-list-bx.nobg-shadow {
    background-color: transparent !important;
    box-shadow: none !important;
}
.university-list-bx .modal-bx-header {
    background-color: #B9943C;
    padding: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.university-list-bx .modal-bx-header .country-name {
    color: #fff;
    font-size: 27px;
    line-height: 1.2;
    font-weight: 600;
}
.university-list-bx .modal-bx-body ul {
    margin: 0;
    padding: 0;
}
.university-list-bx .modal-bx-body ul li {
    list-style: none;
    font-family: "Lato", sans-serif;
}
.contactmodal-bx {
    background-color: #fff;
    border-radius: 15px;
    border: none !important;
    width: 400px !important;
    padding: 0 !important;
}
.contactmodal-bx .modal-bx-header {
    background-color: #B9943C;
    padding: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.contactmodal-bx .modal-bx-header .country-name {
    color: #fff;
    font-size: 27px;
    line-height: 1.2;
    font-weight: 600;
}
.modal-bx-body {
    padding: 30px;
    margin-top: 0 !important;
}
.modal-bx-body.p-0 {
    padding: 0 !important;
}
.modal-bx-body .content {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    color: #182E46;
    padding: 2px 0;
    font-weight: 500;
}
.modal-bx-body .hs-line-5 {
    font-size: 35px;
    font-weight: 600;
}
.modal-bx-body .desc ul {
    margin: 0;
    padding: 0;
}
.modal-bx-body .desc ul li {
    list-style: none;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    color: #182E46;
    padding: 2px 0;
    font-weight: 500;
}
.readmore {
    font-size: 14px;
    color: #B9943C;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 30px;;
    padding: 5px 15px;
    font-weight: 600;
}
.pics-values {
    background-color: #ECECEC;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    min-height: 265px;
    -webkit-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.pics-values:hover {
    background-color: #fff;
    margin-top: -8px;
}
.pics-values .icon img {
    width: 60px;
    height: 60px;
}
.pics-values h4 {
    color: #B9943C;
    font-size: 22px;
    font-weight: 700;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 30px;
}
.pics-values p {
    color: #182E46;
    font-size: 15px;
    margin-bottom: 0;
}
.search-form {
    display: flex;
    align-items: center;
    position: relative;
}
.search-form .search-btn {
    position: absolute;
    right: 10px;
    top: 5px;
    background-color: #182E46;
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    padding: 5px 30px;
    border-radius: 6px;
    color: #fff;
}
.search-form input {
    border: none;
    background-color: #fff;
    height: 38px;
    border-radius: 6px;
    font-family: "Lato", sans-serif;
    padding: 5px 55px;
    position: relative;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.search-form .search-icon {
    position: absolute;
    left: 15px;
    top: 13px;
    z-index: 99;
}
.search-form .MuiOutlinedInput-notchedOutline {
    border:none !important
}
.search-form .MuiInputLabel-root {
    font-family: "Lato", sans-serif;
    line-height: 1em;
    left: 45px;
}
.contact-section .react-datepicker-wrapper {
    display: block;
}
.contact-section .react-datepicker__input-container {
    display: block;
    position: relative;
}
.contact-section .datepicker {
    border: none !important;
    width: 100%;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 6px  50px;
    border-radius: 3px;
    height: 40px;
}
.contact-section .react-datepicker__input-container::before {
    background-image: url('../images/calendar.png');
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    top: 8px;
    content: "";
    width: 25px;
    height: 24px;
    z-index: 99;
}
.contact-section .timepicker fieldset {
    border: none !important;
}
.contact-section .timepicker input {
    border: none !important;
    width: 100%;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 6px  45px;
    border-radius: 3px;
    height: 28px;
}
.contact-section .timepicker .MuiButtonBase-root {
    position: absolute;
    left: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
.MuiSelect-select:-webkit-autofill,
.MuiSelect-select:-webkit-autofill:hover,
.MuiSelect-select:-webkit-autofill:focus,
.MuiSelect-select:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
.split-section-wrapper .MuiSelect-standard {
    width: 100%;
    min-width: 100% !important;
    padding-right: 0 !important;
    padding-left: 20px;
    height: 30px !important;
}
.split-section-wrapper .MuiSelect-standard:focus {
    background-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
}
.contact-section input {
    border: 1px solid #8F8F8F !important;
    height: 34px;
    border-radius: 0px;
    font-family: "Lato", sans-serif;
    padding: 5px 10px;
    font-size: 14px;
    position: relative;
}
.section-title-alt {
    font-size: 18px;
    font-weight: 500;
}
.go-back {
    color: #8F8F8F;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 15px;
}
.go-back span {
    padding: 0 5px;
}
.contact-section .MuiInputLabel-root {
    font-family: "Lato", sans-serif;
    top: 5px;
    left: 20px;
}
.contact-section .selectBox .MuiInputLabel-root {
    font-family: "Lato", sans-serif;
    top: 0px;
}
.contact-section .selectBox .MuiInput-underline {
    font-family: "Lato", sans-serif;
}
.contact-section .selectBox .MuiInput-underline::before  {
    border: 1px solid #8F8F8F !important;
    height: 45px;
}
.contact-section .selectBox .MuiSvgIcon-root {
    right: 10px;
    top: 0;
}
.contact-section .MuiInputLabel-root.Mui-focused,
.contact-section .MuiFormLabel-filled {
    display: none !important
}
.contact-section .MuiInput-underline::after {
    border-bottom: 2px solid #B9943C;
}
.contact-section .terms-label .MuiFormControlLabel-label {
    font-size: 13px;
    font-family: "Lato", sans-serif;
}
.contact-section .terms-label .MuiFormControlLabel-root {
    margin-left: -20px;
    align-items: flex-start;
}
.contact-section .terms-label .MuiCheckbox-root {
    margin-top: -8px;
    align-items: flex-start;
}
.contact-section .terms-label .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    fill: #182E46;
}
.contact-section .terms-link  {
    font-size: 13px;
    margin-left: 35px;
    color: #5f5f5f;
    font-family: "Lato", sans-serif;
}
.contact-section .terms-link a {
    color: #182E46;
    text-decoration: underline !important;
}
.terms-label {
    font-size: 13px;
    color: #5f5f5f;
    font-family: "Lato", sans-serif;
}
.terms-label a {
    color: #182E46;
    text-decoration: underline !important;
}
.form-footer {
    background-color: #162C5F;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
}
.form-footer ul {
    margin: 0;
    padding: 0;
}
.form-footer ul li {
    list-style: none;
    display: inline;
    padding: 0 4px;
}
.form-footer ul li .dot {
    width: 8px;
    height: 8px;
    border-radius: 30px;
    background-color: #8F8F8F;
    display: inline-block;
}
.form-footer ul li .dot.active {
    background-color: #fff !important;
}
.faq-filter {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 6px 0px;
    padding: 15px 20px !important;
    border-radius: 10px;
    width: 200px;
    height: 120px;
    text-align: center;
    cursor: pointer;
    margin: 0 10px !important;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.faq-filter:hover {
    margin-top: -4px;
    background-color: #f5f5f5;
}
.faq-filter h4 {
    color: #8F8F8F;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0px;
}
.faq-filter.active {
    background-color: #192f45;
}
.faq-filter.active h4 {
    color: #fff;
}
.faqs .MuiAccordion-root {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 6px 0px;
    margin: 18px 0;
    border-radius: 8px !important;
    padding: 5px 10px;
    border: 2px solid #182E46;
}
.faqs .Mui-expanded {
    background-color: #182E46;
}
.faqs .MuiAccordion-root::before {
    background-color: transparent !important;
}
.Lato {
    font-family: "Lato", sans-serif !important;
}
.faq-question {
    color: #8F8F8F;
    font-size: 15px;
}
.faqs .Mui-expanded .faq-question {
    color: #fff;
    font-size: 18px !important;
}
.faq-answer {
    font-size: 16px !important;
    color: #fff;
}
.faqs .MuiSvgIcon-root {
    fill: #182E46;
}
.faqs .Mui-expanded .MuiSvgIcon-root {
    fill: #fff;
}
.box-one {
    background-color: #fff;
    border: 2px solid #707070;
    border-radius: 12px;
    padding: 20px;
}
.countryflag button {
    border: 1px solid #8F8F8F !important;
    border-right: none !important;
    height: 46px;
    border-radius: 0px;
    font-family: "Lato", sans-serif;
    padding: 5px 20px;
    font-size: 14px;
    position: relative;
    background: rgba(0,0,0,.05);
}
.countryflag ul::-webkit-scrollbar {
  width: 12px;
}
.countryflag ul::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}
.countryflag ul::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 14px;
  border: 1px solid #ddd;
}
.countryflag ul::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}
.countryflag button span {
    padding: 0;
    margin: 2px 0;
}
.countryflag button span span {
    padding-right: 8px;
}
.countryflag ul {
    overflow-x: hidden;
    min-width: 200px;
    margin-top: 0;
}
.countryflag ul input {
    border: none !important;
    margin: 0 4px;
    padding: 0 5px;
    font-size: 12px;
    width: 100%;
}
.countryflag ul div {
    padding-top: 0;
}
.countryflag ul li {
    padding: 0 5px;
    font-size: 14px;
}
.mt-t4 {
    margin-top: 4px;
}
.home-banner {
    text-align: center;
    margin: 0 15px;
    h3 {
        font-size: 13px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 5px;
    }
}
.headerlist {
    display: flex;
    align-items: center;
    h4 {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 0;
        color: #fff;
    }
}
.why-choose {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #8F8F8F;
    padding: 20px;
    text-align: center;
    min-height: 250px;
    h4 {
        color: #B9943C;
        font-size: 18px;
        margin-top: 15px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0px;
    }
    p {
        font-size: 16px;
        color: #313131;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 10px;
    }
}
.additional-services {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-align: center;
    min-height: 155px;
    margin-bottom: 0px;
    p {
        font-size: 13px;
        color: #8F8F8F;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 15px;
    }
}
.additional-services .description {
    color: #313131;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    padding-left: 0px;
    img {
        max-width: 60px;
    }
}
.additional-services .description p {
    margin-bottom: 0;
}
.additional-services .count {
    color: #182E46;
    font-size: 65px;
    font-weight: 700;
    padding-right: 30px;
}
.additional-services h4 {
    color: #B9943C;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.univ_logo {
    width: 200px;
    height: 120px;
    text-align: center;
    cursor: pointer;
    margin: 0 10px !important;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.contactForm {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px 30px 30px 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.contactForm input {
    border: 1px solid #e7e7e7 !important;
    height: 34px;
    border-radius: 0px;
    font-family: "Lato", sans-serif;
    padding: 5px 10px;
    font-size: 14px;
    position: relative;
}
.contactForm .MuiInputLabel-root {
    font-family: "Lato", sans-serif;
    top: 5px;
    left: 20px;
}

.contactForm .react-tel-input .form-control {
    margin-top: 16px !important;
}
.contactForm .react-tel-input .flag-dropdown {
    border: 1px solid #e7e7e7 !important;
    border-right: none !important
}
.contactForm .selectBox .MuiInputLabel-root {
    font-family: "Lato", sans-serif;
    top: -1px;
}
.contactForm .selectBox .MuiInput-underline {
    font-family: "Lato", sans-serif;
}
.contactForm .selectBox .MuiInput-underline::before  {
    border: 1px solid #e7e7e7 !important;
    height: 45px;
}
.contactForm .selectBox .MuiSvgIcon-root {
    right: 10px;
    top: 0;
}
.contactForm .MuiInputLabel-root.Mui-focused,
.contactForm .MuiFormLabel-filled {
    display: none !important
}
.contactForm .MuiInput-underline::after {
    border-bottom: 2px solid #B9943C;
}
.contactForm .terms-label .MuiFormControlLabel-label {
    font-size: 13px;
    font-family: "Lato", sans-serif;
}
.contactForm .terms-label .MuiFormControlLabel-root {
    margin-left: -20px;
    align-items: flex-start;
}
.contactForm .terms-label .MuiCheckbox-root {
    margin-top: -12px;
    align-items: flex-start;
}
.contactForm .terms-label .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    fill: #182E46;
}
.contactForm .terms-link  {
    font-size: 13px;
    margin-left: 35px;
    color: #5f5f5f;
    font-family: "Lato", sans-serif;
}
.contactForm .terms-link a {
    color: #182E46;
    text-decoration: underline !important;
}
.terms-label {
    font-size: 13px;
    color: #5f5f5f;
    font-family: "Lato", sans-serif;
}
.terms-label a {
    color: #182E46;
    text-decoration: underline !important;
}
.contactForm  .MuiSelect-standard {
    width: 100%;
    min-width: 100% !important;
    padding-right: 0 !important;
    padding-left: 20px;
    height: 30px !important;
}
.contactForm  .MuiSelect-standard:focus {
    background-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
}
.key-benefits {
    padding-right: 30px;
}
.key-benefits .count {
    font-size: 23px;
    font-style: italic;
    font-weight: 700;
}
.key-benefits .divider {
    width: 100%;
    height: 3px;
    margin: 10px 0;
    background-color: #B9943C;
}
.key-benefits .desc {
    color: #8F8F8F;
    font-size: 16px;
}
.inner-column {
    padding-left: 20px;
}
.key-benefits-2 {
    background-color: #182E46;
    border-radius: 10px;
    padding: 25px;
    text-align: center;
    min-height: 213px;
}
.key-benefits-2 .location {
    color: #B9943C;
    margin: 15px 0 5px;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1;
}
.key-benefits-3 {
    background-color: #ECECEC;
    border-radius: 10px;
    padding: 35px;
    min-height: 283px;
}
.alt-service-item{
    margin: 0;
    position: relative;
    padding-left: 55px;
    padding-top: 3px;
    font-size: 13px;
    font-weight: 300;
    color: #777;
    
    -webkit-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -moz-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -o-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -ms-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    
    -webkit-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.alt-service-icon{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 2px;
    left: 0;
    line-height: 40px;
    text-align: center;
    font-size: 28px;
    color: #111;
    
    -webkit-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.alt-services-title{
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 700;
    color: #182E46;
    font-style: italic;
    line-height: 1.2;
}
.highlights {
    border: 0.5px solid #f7d789;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    min-height: 200px;
}
.highlights_2 {
    min-height: 225px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.highlights .desc {
    font-size: 14px;
    color: #8F8F8F;
    font-weight: 500;
    line-height: 1.2;
}
.highlights .icon {
    margin: 25px 0 15px 0;
}
.list-style-2 ul { padding-left: 25px; list-style:none; margin-bottom: 0; }
.list-style-2 ul li {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 400;
    color: #182E46;
    line-height: 1.7;
    padding: 5px 0 0 15px;
    position: relative;
}
.list-style-2 ul li:before {  
    background-image: url('../images/list-style-2.png');
    content: '';
    position: absolute;
    left: 0;
    margin: 4px 10px 0 -25px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
}
.list-style-3 ul { padding-left: 25px; list-style:none; margin-bottom: 0; }
.list-style-3 ul li {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 400;
    color: #182E46;
    line-height: 1.7;
    padding: 5px 0 0 15px;
    position: relative;
}
.list-style-3 ul li:before {  
    background-image: url('../images/list-style-3.png');
    content: '';
    position: absolute;
    left: 0;
    margin: 4px 10px 0 -25px;
    width: 20px;
    height: 21px;
    background-repeat: no-repeat;
}
.img-round img {
    border-radius: 12px;
}
.highlights-2 {
    border: 2px solid #B9943C;
    border-bottom: 25px solid #B9943C;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding: 35px 60px;
    text-align: center;
}
.right-container {
    background-color: #B9943C;
    padding: 30px 30px 30px 85px;
    border-radius: 15px;
    margin-left: -50px;
    position: relative;
}
.right-container p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
}
.bg-gray-lighter {
    background-color: #ECECEC;
}
.right-container-2 {
    border: 2px solid #8F8F8F;
    border-radius: 15px;
    background-color: #fff;
}
.right-container-2 .inner-column {
    padding: 35px;
}
.icon-listing ul {
    margin: 0;
    padding: 0;
}
.icon-listing ul li {
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 20px;
    color: #313131;
}
.icon-listing ul li img {
    margin-right: 15px;
}
.exam_list {
    display: flex;
    gap: 35px;
}
.pros {
    background-color: #B9943C;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    min-height: 303px;
}
.cons {
    background-color: #8F8F8F;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    min-height: 303px;
}
.list-intake {
    background-color: #ECECEC;
    border-radius: 4px;
    padding: 10px 35px;
    text-align: center;
    color: #313131;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}
.timeline {
    margin: 0 auto;
    position: relative;
    left: 120px;
    width: 80%;
    max-width: 900px;
    margin-top: 16px;
    margin-left: 5%;
}
.timeline-item {
    color: #313131;
    text-align: left;
    font-size: 1.4rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border-left: 4px solid #ECECEC;
    position: relative;
    list-style-type: none;
    --item-width: calc(100%);
}
.timeline-item .title {
    color: #B9943C;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.timeline-item::after {
    content: attr(data-date);
    position: absolute;
    right: calc(var(--item-width) + 40px);
    top: 10px;
    float: right;
    font-weight: 500;
    background-color: #ECECEC;
    padding: 10px 40px;
    white-space: nowrap;
    font-size: 18px;
    min-width: 165px;
    text-align: center;
}
.timeline-item::before {
    content: "";
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #B9943C;
    position: absolute;
    left: -10px;
    top: 21px;
}
.timeline-item:last-child {
    border-bottom: none;
}
.navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 12px;
    padding-right: 12px;
}
.navbar-nav {
    align-items: center;
}
.nav-link {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #fff;
    text-transform: uppercase;
}
.nav-link:hover {
    color: #B9943C;
}
.addonMenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.addonMenu ul li {
    display: inline-block;
    padding: 0 30px;
    list-style: none;
}
.addonMenu ul li a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}
.is-sticky {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
  
.customTabs .MuiTab-root {
    border: 1px solid #182E46;
    border-radius: 5px;
    margin: 0 20px;
    padding: 5px 30px;
    text-transform: none;
}
.customTabs .MuiTab-root.Mui-selected {
    background-color: #182E46 !important;
    color: #fff;
}
.PrivateTabIndicator-colorSecondary-3 {
    background-color: transparent !important;
    border: none !important;
}
.stickymenu ul li a.active {
    color: #B9943C;
}
.custom-table {
    border-radius: 15px;
    overflow: hidden;
}
.custom-table table {
    width: 100%;
    border-collapse: collapse;
}
.custom-table table tr th {
    background-color: #B9943C;
    padding: 15px;
    text-align: left;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.custom-table table tr td {
    padding: 15px;
    text-align: left;
    height: 80px;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #ddd;
}
.custom-table table tr:nth-child(odd) {
    background-color: #fff;
}
.custom-table table tr:nth-child(even) {
    background-color: #FEF7E8;
}
.bg-dark-alfa {
    position: relative;
}
.bg-dark-alfa:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(24, 46, 70, .25);
}
.read-more {
    font-size: 14px;
    color: #182E46;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}
.modal-bx-body-2 {
    height: 550px;
    overflow-y: scroll;
    font-family: "Lato", sans-serif !important;
    overflow-x: hidden;
}
.modal-bx-body-3 {
    font-family: "Lato", sans-serif !important;
    overflow-x: hidden;
}
.modal-bx-body-2::-webkit-scrollbar {
    width: 8px;
}
.modal-bx-body-2::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 2px;
}
.modal-bx-body-2::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 14px;
    border: 1px solid #ddd;
}
.modal-bx-body-2::-webkit-scrollbar-thumb:hover {
    background-color: #333;
}
.color-title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    color: #B9943C;
    margin-top: 15px;
}
.wrc-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    margin-bottom: 70px;
}
.education-system {
    border-radius: 15px;
    overflow: hidden;
    margin-right: 160px;
}
.education-system table {
    width: 100%;
    border-collapse: collapse;
}
.education-system table tr th {
    background-color: #B9943C;
    padding: 5px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    border: 1px solid #ddd;
}
.education-system table tr td {
    padding: 5px 10px;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #ddd;
}
.education-system table tr:nth-child(odd) {
    background-color: #fff;
}
.education-system table tr:nth-child(even) {
    background-color: #FEF7E8;
}
.closemodal {
    position:absolute;
    top: -10px;
    right: -10px;
    background-color: #B9943C;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
.border-radius12 {
    border-radius: 12px;
}
.study-abroad {
    background-color: #fff;
    position: relative;
    border-radius: 10px;
    width: 220px;
    margin: 0 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.study-abroad h4 {
    font-size: 20px;
    font-weight: 800;
    font-style: italic;
    padding: 20px 0;
    text-align: center;
    color: #182E46;
}
.study-abroad .enquire {
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translate(-30%, 0);
    width: 100%;
}
.slider-container {
    display: flex;
    overflow: hidden;
    width: 96%;
    padding: 35px 0;
}
.sticky-whatsapp {
    position: fixed;
    right: 30px;
    bottom: 30px;
}