@charset "utf-8";
/*------------------------------------------------------------------
Project: DT Skill
-------------------------------------------------------------------*/
/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {
	
    .inner-nav ul li {
        margin-left: 25px;
    }
	
}  

@media only screen and (max-width: 1200px) {
	
    .hs-wrap {
        max-width: 540px;
    }
    .hs-line-1 {
        letter-spacing: 0px;
    }
    .hs-line-2{
        font-size: 68px;
    }
    .hs-line-12{
        font-size: 70px;
        letter-spacing: 0em;
    }
    .hs-line-13 {
        font-size: 78px;
        letter-spacing: 0em;
    }
    
    .alt-service-grid {
        margin-top: -40px;
    } 
    .alt-service-item {
    }
    .alt-services-title{
    }
    .alt-services-image{
        margin-top: 10px;
    }
    
    .work-grid-5 .work-item{
        width: 25%;
    }
    
    .mod-breadcrumbs-mini{
        text-align: right;
    }
    
}
@media only screen and (max-width: 1199px) {
    
    .tpl-alt-tabs{
        font-size: 13px;
        letter-spacing: 0em;
    }
    .tpl-alt-tabs > li{
        width: 170px;
    }
    
    .split-section-wrapper {
        padding: 40px;
    }
    
}

/* ==============================
   Tablet landskape
   ============================== */

@media only screen and (max-width: 1024px) {
    
    .hs-wrap {
        max-width: 480px;
        padding-top: 37px;
        padding-bottom: 43px;
    }
    
    .hs-line-1 {
        letter-spacing: 0px;
    }
    .hs-line-2{
        font-size: 60px;
    }

    .hs-line-9 {
        font-size: 71px;
    } 
    .hs-line-10 {
        padding: 10px 14px;
        letter-spacing: 0px;
    }
    .hs-line-10 > span {
        margin: 0 1px;
    }
    
    .benefit-item {
        width: 300px;
        padding: 15px 32px;
    }

    .page-section{
        padding: 120px 0;
        
    }
    .page-section, .home-section, .small-section{
        background-attachment: scroll;
    }
    
    .owl-pagination {
        bottom: 25px;
    }
    .section-bot-image {
        margin: 120px 0 -120px;
    }
    
    
    .lp-item {
        width: 50%;
    }
    .lp-item:nth-child(4n + 5){ 
        clear: none; 
    }
    .lp-item:nth-child(2n + 3) {        
        clear:left;
    }
    .lp-title, .lp-author{
        font-size: 13px;
    }

}
  
@media only screen and (max-width: 992px) {
    
    .hs-line-1 {
        letter-spacing: 0px;
    }
    .hs-line-13 {
        letter-spacing: 0em;
    }
    
    .mod-breadcrumbs:not(.align-center):not(.mod-breadcrumbs-mini){
        text-align: left !important;
    }
    
    .tpl-alt-tabs > li{
        width: 200px;
    }
      
    .split-section-headings,
    .split-section-headings.left,
    .split-section-headings.right,
    .split-section-content,
    .split-section-content.left,
    .split-section-content.right{
        float: none !important;
        width: auto;
    }
    
    .split-section-headings .section-title{
        font-size: 35px;
        letter-spacing: 0px;
    }
    
    .banner-text {
        text-align: center;
    } 
    
    .banner-image {
        margin: 40px 0;
    }

    .team-item-name{
        font-size: 13px;
    }
    
    .features-icon {
        font-size: 64px;
    } 
    .features-title {
        font-size: 20px;
    }
    .features-descr{
        font-size: 14px;
    }
    
    .work-item,
    .work-grid-3 .work-item,
    .container .work-grid-3 .work-item,
    .work-grid-5 .work-item{
        width: 50%;
    }
     
    
    .blog-item-date {
        border:none;
    }
    
    
    .countdown > li{
        margin: 0 20px;
    }
    .countdown-number{
        width: 74px;
        height: 74px;
        font-size: 24px;
        line-height: 64px;
    }
    .countdown-number > b{
        bottom: 18px;
    }   
    
}
  
  
/* ==============================
   Tablet Portrait
   ============================== */
  
@media only screen and (max-width: 768px) {

    .hs-wrap {
        max-width: 400px;
    }
    .hs-line-2{
        font-size: 50px;
    }
    
    .hs-line-7 {
        font-size: 14px;
    }
    
    .page-section {
        padding: 80px 0;
    }    
    .small-section {
        padding: 50px 0;
    }
    .small-title{
        font-size: 18px;
    }
    
    .owl-pagination {
        bottom: 17px;
    }
    
    .section-bot-image {
        margin: 70px 0 -70px;
    }
    
    
    .tpl-minimal-tabs > li {
        padding: 0 5px;
    }
    
        
    .benefit-item{
        width: 264px;
    }
    
    .count-descr{
        margin-bottom: 30px;
    }    
    
    .alt-service-grid{
    margin-top: -30px;
    }
    .alt-service-item {
        margin-top: 0px;
    }

    .works-filter a{
        padding-left: 10px;
        padding-right: 10px;
        margin: 4px;
    }
    
    .blog-item {
        margin-bottom: 50px;
    }
    .blog-item-date{
        top: 0;
        left: -100px;
    }
    .blog-item-title{
        font-size: 20px;
    }
    .blog-item-title .small{
        font-size: 15px;
    }
    .blog-item-body{
        font-size: 14px;
    }
    .blog-media{
        margin-bottom: 15px;
    }
    .blog-item-body .small{
        font-size: 13px;
    }

    .google-map > iframe{
        height: 300px;
    }
    
    .fm-logo-wrap{
        top: 17px;
        left: 17px;
    }
    .fm-button{
        top: 17px;
        right: 17px;
    }

}

@media only screen and (max-width: 767px) {
    .nav-logo-wrap {
        margin-right: 5px;
    }
    .pics-abbr .desc-btn span:after {
        content: none;
    }
    .pics-abbr .desc-btn {
        padding: 10px;
    }
    .step-container .description p {
        font-size: 12px;
    }
    .step-container .buttonlink3 {
        position: relative;
        bottom: 0;
    }
    .wrc-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 50px;
    }
    .key-benefits .count {
        font-size: 16px;
    }
    .key-benefits .desc {
        font-size: 15px;
    }
    .alt-service-item {
        padding-bottom: 12px;
    }
    .alt-service-icon {
        top: -8px;
    }
    .key-benefits-2 {
        display: flex;
        align-items: center;
        min-height: auto;
        text-align: left;
    }
    .key-benefits-2 img {
        max-width: 50px;
        margin-right: 15px;
    }
    .key-benefits-2 .location {
        margin-top: 0;
    }
    .alt-services-title br {
        display: none;
    }
    .abroad-pages-height {
        min-height: 450px;
    }
    .abroad-pages-height .js-height-parent {
        height: 450px;
    }
    .m-left {
        text-align: left !important;
    }
    .highlights {
        padding: 15px;
        min-height: 200px;
    }
    .highlights-2 {
        padding: 30px;
        text-align: left;
    }
    .education-system {
        margin-right: 0;
    }
    .highlights_2 {
        min-height: 225px;
    }
    .key-benefits-3 {
        min-height: auto;
    }
    .custom-table {
        overflow-x: scroll;
    }
    .right-container-2 .inner-column {
        padding: 25px;
    }
    .navbar-expand-lg .navbar-collapse {
        background-color: rgba(33, 33, 33, 0.9) !important;
        border-radius: 10px;
    }
    .customTabs .MuiTab-root {
        border: 1px solid #182E46;
        border-radius: 5px;
        margin: 0px 5px;
        padding: 2px 14px;
        text-transform: none;
        white-space: nowrap;
        max-width: 180px;
        font-size: 12px;
        min-height: 30px;
    }
    .timeline {
        left: 0px;
        width: 100%;
    }
    .timeline-item::before {
        left: -10px;
        top: 20px;
    }
    .timeline-item::after {
        content: attr(data-date);
        position: absolute;
        left: -75px;
        top: 10px;
        padding: 5px 4px;
        font-size: 13px;
        min-width: 75px;
        text-align: center;
    }
    .list-style-3 ul li {
        padding-left: 10px;
    }
    .exam_list {
        gap: 10px;
    }
    .icon-listing ul li img {
        max-width: 50px;
    }
    .right-container {
        margin-left: 0px;
    }
    .right-container {
        padding: 30px;
    }
    .footer-widget .map-link { 
        width: 100%;
    }
    .fixed-height-small-m {
        min-height: 600px !important;
    }
    .loader {
        margin-left: 20px;
      }
    .dblock {
        display: none;
    }
    .mblock {
        display: block
    }
    .mblock .footer-widget {
        font-size: 13px;
    }
    .footer-widget .title {
        color: #B9943C;
    }
    .mblock .footer-widget h4 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    .footer-menu ul li a {
        letter-spacing: 0;
        font-weight: 600;
    }
    .footer-copy {
        color: #182E46;
        font-size: 13px;
        font-weight: 500;
        text-transform: none;
        letter-spacing: 0;
    }
    .hs-wrap {
        max-width: 320px;
        padding-top: 27px;
        padding-bottom: 33px;
    }
    .inner-nav ul {
        font-size: 12px;
    }
    .pics-service {
        min-height: auto;
    }
    .pics-service h2 {
        font-size: 20px;
        padding: 0;
        margin-top: 15px;
    }
    .pics-service h2 br {
        display: none;
    }
    .search-form .search-btn {
        padding: 2px 15px;
        top: 9px;
    }
    .md-block {
        display: block !important
    }
    .footer-menu ul li {
        display: block;
        padding: 3px 0;
    }
    .md-block2 {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        gap: 10px;
        grid-row-gap: 10px;
        row-gap: 10px;
    }
    .img-container {
        width: 100%;
        display: block !important;
        overflow: visible;
    }
    .faq-categories button {
        display: none !important
    }
    .faq-filter {
        width: 90%;
        height: auto;
        padding: 10px 12px !important;
    }
    .faq-filter h4 {
        margin-top: 0;
        font-size: 14px;
    }
    .faq-filter .icon {
        display: none
    }
    .study-abroads-list button {
        display: none
    }
    .slider-container {
        overflow-x: scroll;
        padding: 0 0 35px 0;
    }
    .nav-logo-wrap .logo {
        height: 75px;
    }
    .split-section-wrapper {
        padding: 0px 25px 95px 25px;
    }
    .contact-section .datepicker {
        padding-right: 10px;
    }
    .split-section-content {
        height: auto;
    }
    .split-section-content .btn-mod.btn-large{
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0;
        border-radius: 0;
        justify-content: center;
        font-size: 18px;
        z-index: 99;
    }
    .ssh-table {
        display: none;
    }
    .CookieConsent {
        padding: 10px;
        width: 90% !important;
        font-size: 11px !important;
        left: 0 !important;
    }
    .form-footer {
        bottom: auto;
        top: 0;
        padding: 2px 0;
    }
    .form-footer.push-bottom {
        bottom: 0;
    }
    .contact-section .timepicker input {
        padding-right: 10px;
    }
    .xs-order-1 {
        order: 1;
    }
    .xs-order-2 {
        order: 2;
    }
    .xs-order-3 {
        order: 3;
    }
    .xs-order-4 {
        order: 4;
    }
    .color-title {
        font-size: 16px;
    }
    .bg-dark-alfa:before {
        background: rgba(24, 46, 70, .65);
    }
    .ssh-cell {
        height: 450px;
    }
    .hs-line-1 {
        font-size: 28px;
    }
    .hs-line-2{
        font-size: 18px;
    }   
    .home-section .hs-line-2 br{
        display: none;
    } 
    .js-height-parent {
        height: 560px;
    }
    .hs-line-3 {
        font-size: 30px;
    }
    .hs-image{
        display: none;
    }
    .m-center {
        text-align: center !important;
    }
    .m-justifyy-center {
        justify-content:center !important;
    }
    .inner-nav ul li {
        padding: 0;
        margin-left: 9px;
    }
    .inner-nav ul li .btn-mod.btn-medium {
        padding: 2px 6px;
        font-size: 11px;
    }
    .whatsapp-icon {
        margin-left: 8px;
    }
    .whatsapp-icon img {
        max-width: 30px;
    }
    .loader { 
        left: 45%;
    }
    .university-list-bx {
        width: 100% !important;
    }
    .menu-bg {
        height: 70px !important;
    }
    .menu-bg .inner-nav {
        margin-top: 15px;
    }
    .menu-bg .nav-logo-wrap .logo {
        height: 55px !important;
    }
    .headerlist {
        display: block;
        text-align: center;
    }
    .headerlist .me-3 {
        margin-right: 0 !important;
    }
    .headerlist img {
        max-width: 40px;
        margin: 0 auto 5px;
    }
    .headerlist h4 {
        font-size: 13px;
    }
    .home-banner img {
        max-width: 50px;
        margin: 0 auto 5px;
    }
    .university-list-bx .modal-bx-header .country-name {
        font-size: 18px;
    }
    .home-banner h3 {
        margin-bottom: 5px;
    }
    .js-height-parent-book {
        height: 1000px;
    }
    .why-choose {
        padding: 12px;
        min-height: 185px;
        margin-bottom: 20px;
        .description img {
            max-width: 50px;
            margin: 0 auto;
        }
        h4 {
            font-size: 14px;
        }
        p {
            font-size: 13px;
        }
    }
    .contactForm {
        margin-top: 0px;
    }
    .m-push-xs-top {
        padding-top: 340px !important;
    }
    .univ-box {
        width: 160px;
        height: 130px;
    }
    .univ_logo {
        width: 100px;
        height: 80px;
    }
    .additional-services {
        margin-bottom: 20px;
    }
    .booknow-section .md-block2 {
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }
    .booknow-section .md-block2 .icon{ 
        width: 90px;
    }
    .booknow-section .md-block2 .icon img {
        max-width: 80px;
        margin: 0 auto;
    }
    .univ-box:nth-child(5),
    .univ-box:nth-child(6),
    .univ-box:nth-child(7),
    .univ-box:nth-child(8),
    .univ-box:nth-child(9),
    .univ-box:nth-child(10) {
        display: none;
    }
    .step-container {
        min-height: 171px;
        padding: 12px;
    }
    .step-container h4 {
        font-size: 16px;
    }
    .step-container .description img {
        height: 50px;
        margin: 0 auto;
    }
    .step-container .count {
        font-size: 48px;
    }
    .border-radius12 {
        width: 100% !important;
        height: 550px;
        overflow-y: scroll;
    }
    .closemodal {
        right: 10px;
        top: 10px;
    }
    .step-container .description {
        color: #313131;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.5;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 170px;
    }
    .univ-box .icon {
        width: 90px;
    }
    .hs-wrap-1{
        max-width: 240px;
    }    
    .hs-line-5{
        font-size: 44px;
        letter-spacing: 0em;
    }
    .hs-line-6{
        font-size: 16px;
    }
    .hs-line-7{
        font-size: 14px;
    }
    
    .hs-line-9{
        font-size: 48px;
    } 
    .hs-line-10 {
        padding: 5px 8px;
        font-size: 11px;
        letter-spacing: 0px;
    }
    .hs-line-10 > span {
        margin: 0;
    }
    
    .hs-line-11{
        font-size: 24px;
    }
    
    .hs-line-12{
        font-size: 44px;
    }
    .hs-line-13{
        font-size: 60px;
        letter-spacing: 0em;
    }
    .hs-line-14{
        font-size: 24px;
        letter-spacing: 0em;
    }
    .hs-line-15{
        font-size: 32px;
    }
    
    .team-item{
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .alt-features-grid {
        margin-top: -30px;
    } 
    .alt-features-item {
        margin-top: 30px;
    }

    .benefit-item{
        width: 230px;
        padding: 0;
    }
    
    .benefit-item:hover:after{
        display: none;
    }
    
    .wide-heading {
        font-size: 32px;
        letter-spacing: 0px;
    }
    
    .works-filter a{
        padding-left: 10px;
        padding-right: 10px;
        margin: 4px;
        width: 46%;
        font-size: 11px;
    }    
    
    .blog-item-date{
        width: auto;
        position: relative;
        top: auto;
        left: auto;
        padding: 0;
        text-align: left;
    }
    .date-num{
        display: inline !important;
        margin: 0 5px 10px 0;
    }
    .sidebar {
        margin-top: 50px;
    } 
    
    blockquote p {
        font-size: 20px;
    }
    
    blockquote footer {
        margin-top: 0;
        font-size: 12px;
    }   

    .banner-content {
        text-align: center;
    } 
    .banner-image {
        text-align: center;
    }
    
    
    .cf-left-col,
    .cf-right-col{
        width: auto;
        margin-right: 0;
        float: none;
    }
    
    .section-more,
    .section-more.left,
    .section-more.right{
        float: none !important;
        display: block;
    }
    
    .big-icon{
        width: 60px;
        height: 60px;
        line-height: 56px;
        font-size: 24px;
        margin-bottom: 0;
    }
    
    .blog-slide-info{
        padding: 10px;
    }
    .blog-slide-title{
        font-size: 12px;
    }
    .blog-slide-data{
        display: none;
    }
    
    .top-bar{
        min-height: 0;
    }
    .top-links{
        float: none !important;
        padding: 5px 0;
        text-align: center;
    }
    .top-links li,
    .top-links li:last-child{
        float: none;
        border:none;
        display: inline-block;
    }
    .top-links li a{
        display: inline-block;
        min-width: 0;
        height: auto;
        line-height: 1;
        padding: 0 5px;
    }
    .top-links li a:hover,
    .top-bar.dark .top-links li a:hover{
        background: none;
    }
    
    .side-panel{
        padding-bottom: 20px;
    }
    .sp-logo-wrap{
        max-width: 100px;
        margin: 0 auto;
    }
    .sp-logo-wrap .logo{
        padding: 20px 0 0 0;
    }
    .sp-menu-links a{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .sp-social-links{
        width: auto;
        position: static;
        padding-bottom: 10px;
    }
    .logo img {
        max-width: 150px;
    }
    .navbar-toggler {
        border: 1px solid #fff;
    }
    .navbar-toggler-icon {
        background-image: url('../images/menu.svg');
    }
    .stickymenu {
        overflow: scroll;
    }
    .addonMenu ul li {
        line-height: 1.2;
    }
    .inner-column {
        padding-left: 0;
    }
    .countdown > li{
        margin: 0 10px 20px;
    }
    .countdown-number{
        width: 44px;
        height: 44px;
        font-size: 20px;
        line-height: 44px;
    }
    .countdown-number > b{
        bottom: -20px;
        font-size: 9px;
        color: rgba(255,255,255, .85);
    } 
    .text-mobile {
        font-size: 110%;
        line-height: 1.2;
    }
    .inner-container {
        padding-left: 0;
    }
    .pull-top {
        margin-top: -130px;
    }
    .xs-hide {
        display: none
    }
    .pics-tabs, .pics-tabs .MuiTabs-flexContainer {
        display: inline-block !important;
        text-align: center;
    }
    .tooltip {
        right: -20px;
    }
    .pics-tabs .MuiTabs-scroller {
        display: inline-block !important;
        white-space: normal !important
    }
    .pics-tabs .tab-single {
        display: inline-block;
        min-width: 170px;
    }
}


/* ==============================
   Phone
   ============================== */
  
@media only screen and (max-width: 480px) {
    
    .btn-mod.btn-large {
        padding: 8px 15px;
        font-size: 13px;
    }
    .form input[type="text"].input-lg,
    .form input[type="email"].input-lg,
    .form input[type="number"].input-lg,
    .form input[type="url"].input-lg,
    .form input[type="search"].input-lg,
    .form input[type="tel"].input-lg,
    .form input[type="password"].input-lg,
    .form input[type="date"].input-lg,
    .form input[type="color"].input-lg,
    .form select.input-lg{
        height: 38px;
    }
    .hs-line-1 {
        font-size: 25px;
        letter-spacing: 0px;
    }
    .hs-line-2{
        font-size: 18px;
    }
    .hs-line-6 {
        font-size: 15px;
    }
    .hs-line-7 {
        font-size: 14px;
    }
    .hs-line-8 {
        font-size: 20px;
    } 
    .hs-line-9 {
        font-size: 42px;
    } 
    .hs-line-11 {
        font-size: 20px;
        letter-spacing: 0em;
    } 
    .hs-line-12 {
        font-size: 32px;
    }
    .hs-line-13 {
        font-size: 30px;
        letter-spacing: 0em;
    }
    
    .hs-line-15{
        font-size: 24px;
    }
   
   .scroll-down {
        bottom: 10px;
        height: 34px;
        line-height: 34px;
    }
    .scroll-down-icon {
        width: 22px;
        margin-left: -11px;
    }    
    .scroll-down span{
        display: none;
    }
    
    .nav-bar-compact{
        top: 0;
        left: 0;
    }
    
    
    .page-section {
        padding: 60px 0;
    }
    .small-section {
        padding: 30px 0;
    }
    
    .owl-pagination {
        bottom: 10px;
    }    
    
    .owl-prev, .owl-next {
        display: none;
    }
    .owl-page {
        padding-left: 8px;
        padding-right: 8px;
    }
    
    .section-bot-image {
        margin: 50px 0 -50px;
    }
    
    .section-title,
    .split-section-headings .section-title,
    .wide-heading {
        font-size: 24px;
        letter-spacing: 0px;
    }    
    .section-heading,
    .sub-heading{
        font-size: 15px;
    } 
    .section-text {
        font-size: 15px;
        line-height: 1.7;
    }
    
    .tpl-minimal-tabs > li {
        padding: 0;
    }
    .tpl-minimal-tabs > li > a {
        padding: 6px 10px 5px;
    }
    .tpl-alt-tabs{
        font-size: 12px;
        letter-spacing: 0em;
    }
    .tpl-alt-tabs > li{
        width: 120px;
    }
    .alt-tabs-icon{
        font-size: 32px;
    }
    
    .work-container {
        padding: 30px 0;
    }
    .margin-bot{
        margin-bottom: 30px;
    }
    
    .section-text, 
    .text{
        line-height: 1.7;
    }
        
    .count-number{
        font-size: 32px;
    }
    .count-title{
        font-size: 12px;
    }

    
    .works-filter a{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }    
    
    .work-item,
    .work-grid-2 .work-item,
    .work-grid-3 .work-item,
    .container .work-grid-3 .work-item,
    .work-grid-5 .work-item {
        width: 100%;
    }    
    
    .work-full-text{
        margin-top: 30px;
    }
    .work-full-title{
        font-size: 20px;
    }
    
    .blog-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .blog-item-title{
        font-size: 16px;
    }

    .blog-item-q p{
        font-size: 16px;
    }
    .comment-avatar{
        width: 30px;
        margin-top: 7px;
    }
    
    .comment-item{
        font-size: 13px;
    }
    
    .separ-space{
        display: block;
        height: 10px;
    }
    
    
    .bot-menu-item{
        font-size: 16px;
    }
    
    .google-map > iframe{
        height: 300px;
    }
    
   .fixed-height-small{
        min-height: 300px;
    }
    .fixed-height-medium{
        min-height: 560px;
    }
    .fixed-height-large{
        min-height: 400px;
    }
    
    .tpl-tabs > li{
        display: block;
        float: none;
        width: 100%;
    }
    .tpl-tabs > li.active a{
        background: #f5f5f5 !important;
    }
    
    .fm-menu-links a{
        font-size: 14px;
    }
    .fm-search {
        width: 200px;
    }
}


/* ==============================
   Small Phone
   ============================== */
  
@media only screen and (max-width: 319px) {
    
      .nav-social-links{
          display: none;
      }
}


/* ==============================
   Max Height 768
   ============================== */

@media only screen and (max-height: 767px) {

    .sp-social-links {
        padding-bottom: 10px;
        position: static;
        width: auto;
    }
}


/* ==============================
   IOS Background Fix
   ============================== */
  
.mobile .page-section, .mobile .home-section, .mobile .small-section{
    background-attachment: scroll;
}

/*
 * iPad Pro
 */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    html:not(.no-touch) .page-section, html:not(.no-touch) .home-section, html:not(.no-touch) .small-section{
        background-attachment: scroll;
    }
}


/* ==============================
   Print Styles Fix
   ============================== */
  
@media print {
    .main-nav{
        display: none;
    }
    .buy-button{
        display: none;
    }
    img{
        display: block;
    }
}